import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import iconMail from '../../../assets/icons/icons-mail.png';
import iconLocation from '../../../assets/icons/icons-map-pin.png';
import ContactForm from './ContactForm';
import style from './style.module.css';

export default function AboutFooter({ classname }) {
  const contactUs = () => {
    window.open('https://0u0c51hhbc2.typeform.com/to/NATfnS4l', '_blank');
  };
  return (
    <div className={` ${classname != 'contact' ? style.wsFooterCard : 'px-4'}`}>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs="12" sm="6" className="text-left d-flex flex-column justify-content-center mb-5">
            <div className="text-uppercase text-primary main-title channel-logo">
              <span style={{ color: '#fff', fontWeight: 'bolder', fontSize: '18px' }}>İLETİŞİM</span>
            </div>
            {/* <h5 className="mt-4">Bize Ulaş</h5> */}
            <p className="w-75 pt-2 pb-3" style={{ fontSize: '13px' }}>
              Aşağıdaki bilgiler ile bizimle iletişime geçebilirsin.
            </p>

            <div>
              <div className="d-flex align-items-center mb-2">
                <div className="mr-3">
                  <img width={'20px'} src={iconMail} />
                </div>
                <div>
                  <span className="text-uppercase text-primary " style={{ fontSize: '14px' }}>
                    E-POSTA
                  </span>
                  <h6 className="mt-1 fs-6">destek@wisenrise.com </h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <div className="mr-3">
                  <img width={'20px'} src={iconLocation} />
                </div>
                <div>
                  <span className="text-uppercase text-primary " style={{ fontSize: '14px' }}>
                    adres
                  </span>
                  <h6 className="mt-1 fs-6">Akat Mah. Cebeci Cad. No:65 Beşiktaş/İstanbul</h6>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-around">
              <Button type="submit" className="btn btn-hover btn-primary1 mt-3 w-50" onClick={contactUs}>
                Bize Ulaşın
              </Button>
              <div></div>
            </div>
          </Col>
          {/* <Col xs="12" sm="6" className="d-flex justify-content-center align-items-center">
            <div className="">
            
            </div> */}
          {/* <ContactForm /> */}
          {/* </Col> */}
        </Row>
      </Container>
    </div>
  );
}
