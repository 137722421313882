import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function CommercialElectronicMessage() {
  return (
    <div>
      <div className=" " style={{ paddingTop: '80px', paddingBottom: '60px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Ticari Elektronik İleti Onay Metni</h1>
                  <span>
                    <Link to="/">Anasayfa</Link> /<span className="text-primary">Ticari Elektronik İleti Onay Metni</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                <div className="mb-1">
                  <p className="my-3">
                    Bu Bildirim; Wise&Rise içerik hizmetlerini tanıtmak ve pazarlamak amacıyla elektronik iletişim araçlarıyla gerçekleştireceğimiz
                    ticari iletişime dair 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve 29417 sayılı Resmi Gazete’de yayınlanan
                    Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik kapsamında bilgi verme ve onay alma yükümlülüğümüzü
                    açıklamaktadır. Ayrıca verilen onayın nasıl geri alınacağına da yer verilmiştir.
                  </p>
                </div>
                <div>
                  <p className="my-3">
                    Biz; Veranova Medya ve Dijital Yayın Hizmetleri Anonim Şirketi olarak,
                    <Link to="/kullanim-kosullari" target="_blank" className="text-danger">
                      {' '}
                      Kullanım Koşulları
                    </Link>{' '}
                    ile{' '}
                    <Link to="/mesafeli-sozlesme" target="_blank" className="text-danger">
                      Üyelik ve Mesafeli Satış Sözleşmesi
                    </Link>{' '}
                    kapsamında e-posta ve telefon bilgilerinizi 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun ve ilgili mevzuata uygun
                    şekilde toplamakta ve işlemekteyiz. Detaylı bilgi için lütfen Gizlilik Bildirimi, Çerez Politikası ve{' '}
                    <Link to="/gizlilik-politikasi" target="_blank" className="text-danger">
                      KVKK Aydınlatma Metni{' '}
                    </Link>{' '}
                    ’ni inceleyiniz.
                  </p>
                </div>
                <div>
                  <p className="my-3">
                    Siz; bu bildirime onay vermeniz durumunda, Wise&Rise içerik hizmetlerine ilişkin olarak e-posta ve/veya mesaj yoluyla çeşitli
                    ticari amaçlı tanıtım, kampanya ve bilgilendirmeler yapılmasına açık rıza verdiğinizi kabul etmiş sayılırsınız. Bu olumlu irade
                    beyanınız kutlama ve temenni iletilerini de kapsar.
                  </p>
                </div>
                <div>
                  <p className="my-3">Vermiş olduğunuz bu onayınızı aşağıdaki yöntemlerle her zaman geri alabilirsiniz:</p>
                </div>

                <div className="mb-3 px-5">
                  <ol>
                    <li>
                      İleti Yönetim Sistemi üzerinden (
                      <a href="https://vatandas.iys.org.tr" target="_blank">
                        https://vatandas.iys.org.tr
                      </a>
                      ){' '}
                    </li>
                    <li>
                      <a href="mailto:info@wisenrise.com">info@wisenrise.com </a>üzerinden
                    </li>
                    <li>Tarafınıza göndereceğimiz iletiler altında bulunan link üzerinden </li>
                  </ol>
                  <br />
                </div>
                <div className="mb-3">
                  <p>Ret beyanı bizlere ulaştıktan sonraki 3 iş günü içinde ticari elektronik ileti gönderimini durduruyoruz.</p>
                </div>

                <div className="mt-5">
                  <span className="h6 d-block text-center">Saygılarımızla,</span>
                  <h5 className="h4 text-center">Veranova Medya ve Dijital Yayın Hizmetleri Anonim Şirketi</h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
