import React, { useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { toast } from 'react-toastify';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import closeIcon from '../../assets/icons/x.png';

const ShereBtn = ({ link, title, content, type }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const linkUrl = `https://${window.location.host}${link}`;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const notify = async () => {
    await navigator.clipboard.writeText(linkUrl);
    toast.success('Başarıyla kopyalandı!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
    // handleClose()
  };
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="">
        <DropdownToggle className="cursor-pointer text-white" tag="div">
          Paylaş
        </DropdownToggle>
        <DropdownMenu className="bg-dark ">
          <DropdownItem className="text-white h2">
            <WhatsappShareButton url={window.location.href}>
              <i className="fa fa-whatsapp mr-1"></i> Whatsapp
            </WhatsappShareButton>
          </DropdownItem>
          <DropdownItem className="text-white h2">
            <FacebookShareButton url={window.location.href} quote={title} className="Demo__some-network__share-button">
              <i className="fa fa-facebook mr-2"></i> Facebook
            </FacebookShareButton>
          </DropdownItem>
          <DropdownItem className="text-white h2">
            {' '}
            <TwitterShareButton url={window.location.href} title={title} className="Demo__some-network__share-button">
              <i className="fa fa-twitter mr-1"></i> Twitter
            </TwitterShareButton>
          </DropdownItem>
          <DropdownItem className="text-white h2">
            <LinkedinShareButton url={window.location.href} title={title} summary={content} className="Demo__some-network__share-button">
              <i className="fa fa-linkedin mr-2"></i>Linkedin
            </LinkedinShareButton>
          </DropdownItem>
          <DropdownItem onClick={handleShow} className="text-white h2">
            <i className="fa fa-copy mr-1"></i> Kopyala
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered style={{ maxWidth: '500px', margin: '0 auto' }}>
        <Modal.Body style={{ background: '#272C32', textAlign: 'center' }}>
          <Row className="m-profile" style={{ background: 'transparent', padding: '20px' }}>
            <Col>
              <div className="d-flex justify-content-end m-0 p-0">
                <div className="p-0" onClick={handleClose} style={{ cursor: 'pointer', zIndex: '999' }}>
                  <img width={'30'} src={closeIcon} alt="" />
                </div>
              </div>
              <div className="we-header-title-modal text-center">
                <h5 className="text-primary p-1">Paylaş</h5>
                <span>Eğitimlerimizi izlemesini istediğin kişilerle paylaşabilirsin</span>
              </div>
              <div className="path mt-3">{linkUrl}</div>
            </Col>
          </Row>
          <div onClick={notify} className="btn btn-hover btn-copy ">
            Kopyala
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShereBtn;
