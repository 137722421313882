import React, { useState, useEffect, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../Services/User';

const AuthConntext = createContext({});

const AuthProvider = ({ children }) => {
  let history = useHistory();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [inLogin, setInLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [regId, setRegId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [resisterInMobile, setResisterInMoile] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('ws-user-data')) {
      let profile1 = JSON.parse(localStorage.getItem('ws-user-profile'));
      if (profile1 === null) history.push('/who-is-watch');
      else if (!profile1?.is_survey_completed) history.push('/survey');
      setIsAdmin(user?.roles[0].name == 'Admin' ? true : false);
      getUserInfo()
        .then(() => {})
        .catch(() => {
          setInLogin(false);
          localStorage.removeItem('ws-user-data');
          localStorage.removeItem('ws-user-profile');
          localStorage.removeItem('ws-user-token');
          localStorage.removeItem('ws-video-teacher');
          localStorage.removeItem('ws-access_token');
          localStorage.removeItem('ws-user-regdata');
          localStorage.removeItem('ws-video-list');

          setUser(null);
          setIsAdmin(false);
          setProfile(null);
          if (
            window.location.pathname === '/my-progress' ||
            window.location.pathname === '/my-certificates' ||
            window.location.pathname === '/completed' ||
            window.location.pathname === '/admin-page' ||
            window.location.pathname === '/account-settings'
          )
            history.push('/');
        });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (localStorage.getItem('ws-user-data')) {
      let user = JSON.parse(localStorage.getItem('ws-user-data'));
      let profile1 = JSON.parse(localStorage.getItem('ws-user-profile'));

      setProfile(profile1);
      setUser(user);
      setInLogin(true);
      localStorage.setItem('ws-access_token', user?.token);
    }
    if (localStorage.getItem('ws-user-regdata')) {
      let data = JSON.parse(localStorage.getItem('ws-user-regdata'));
      setRegId(data);
    }
  }, []);

  const login = (data) => {
    setInLogin(true);
    localStorage.setItem('ws-user-data', JSON.stringify(data));
    localStorage.setItem('ws-access_token', data.token);
    setUser(data);
  };
  const logout = (data) => {
    setInLogin(false);
    localStorage.removeItem('ws-user-data');
    localStorage.removeItem('ws-user-profile');
    localStorage.removeItem('ws-user-token');
    localStorage.removeItem('ws-video-teacher');
    localStorage.removeItem('ws-access_token');
    localStorage.removeItem('ws-user-regdata');
    localStorage.removeItem('ws-video-list');

    setUser(null);
    setIsAdmin(false);
    setProfile(null);
    history.push('/');
  };

  const Profile = (data) => {
    localStorage.setItem('ws-user-profile', JSON.stringify(data));
    setProfile(data);
  };
  const RegStep = (data) => {
    localStorage.setItem('ws-user-regdata', JSON.stringify(data.data));
    setRegId(data?.data);
  };
  const Refresh = () => {
    setRefresh(!refresh);
  };
  const openMenu = () => {
    setShowMenu(!showMenu);
  };
  const openRightMenu = () => {
    setShowRightMenu(!showRightMenu);
  };
  const goCurrentUrl = () => {
    setShowRightMenu(!showRightMenu);
  };
  const values = {
    user,
    inLogin,
    profile,
    login,
    Profile,
    logout,
    regId,
    RegStep,
    setRefresh,
    Refresh,
    isAdmin,
    setResisterInMoile,
    resisterInMobile,
    selectedPlan,
    setSelectedPlan,
    refresh,
    showMenu,
    openMenu,
    openRightMenu,
    showRightMenu,
    goCurrentUrl,
  };

  return <AuthConntext.Provider value={values}>{children}</AuthConntext.Provider>;
};

const useAuth = () => useContext(AuthConntext);

export { AuthProvider, useAuth };
