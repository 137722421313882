import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Nav, Tab, Modal } from 'react-bootstrap';
import { getTeacher } from '../../Services/TeachersApi';
import TeacherItem from '../TeacherItem';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function TeachersSlider({ link }) {
  const [teachers, setTeachers] = useState([1, 1, 1, 11, , 1, 1]);
  const { isLoading, error, data } = useQuery(['sorted/the_best'], getTeacher);
  useEffect(() => {
    if (data) setTeachers(data.data);
    return () => {};
  }, [data]);
  if (isLoading) return 'Yükleniyor...';

  if (error) return 'An error has occurred: ' + error.message;
  //setTeachers(data.data)

  return (
    <Container>
      <Row>
        <Col sm="12" className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="main-title channel-logo w-75 text-left">Türkiye'nin En İyileri</h4>
            <div className="d-flex align-items-center justify-content-between">
              <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                <div id="prev311" className="">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div id="next311" className="">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>

              <Link className="iq-view-all" to={link}>
                Tümü
              </Link>
            </div>
          </div>
          <div id="favorites-contens">
            <Swiper
              navigation={{
                prevEl: '#prev311',
                nextEl: '#next311',
              }}
              breakpoints={{
                320: { slidesPerView: 2 },
                550: { slidesPerView: 2 },
                991: { slidesPerView: 4 },
                1400: { slidesPerView: 5 },
              }}
              loop={true}
              slidesPerView={5}
              spaceBetween={20}
              as="ul"
              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
            >
              {teachers?.map((teacher, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    as="li"
                    style={{
                      marginRight: '15px',
                    }}
                  >
                    <TeacherItem teacher={teacher} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
