import axios from 'axios';
const API = process.env.REACT_APP_BASE_ENDPOIND;
const change_password = `${API}change_password`;

export const Change = async (body) => {
  const { data } = await axios.post(`${change_password}`, body);
  return data;
};
export const ForgotPassword = async (body) => {
  const { data } = await axios.post(`${API}forgot_password`, body);
  return data;
};
export const ResetPasswordApi = async (body) => {
  const { data } = await axios.post(`${API}reset_password`, body);
  return data;
};
export const getUserInfo = async () => {
  const { data } = await axios.get(`${API}me`);
  return data;
};

export const getUsersCertificate = async (classroom_id) => {
  const { data } = await axios.get(`${API}user/certificates/${classroom_id}`);
  return data;
};
