import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useApi } from '../../../contexts/RequestContext';
import style from './style.module.css';
import bg from '../../../assets/images/bg/catbg.png';
import RightIcon from '../../../assets/icons/chevron-right.svg';
export default function CategoryList() {
  const { categories, setCat } = useApi();

  return (
    <Row>
      {categories?.slice(0, 9)?.map((item, index) => {
        return (
          <Col key={index} xl="4" lg="12" md="12" className="mb-3">
            <Link
              onClick={() => setCat(item)}
              to={`/${item?.slug}`}
              className={`${style.wsAnswerCardImg} `}
              title={item?.name}
              style={{ backgroundImage: `url(${item?.banner ? item?.banner?.path : bg})` }}
            >
              {item?.name} <img src={RightIcon} className={`${style.icon}`} alt="icon" />
            </Link>
          </Col>
        );
      })}
    </Row>
  );
}
