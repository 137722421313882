import React, { useState } from 'react';
import style from './style.module.css';
import heart from '../../assets/icons/icons-heart.png';
import CertificateModal from '../CertivicateModal';
import { Modal } from 'react-bootstrap';
import bgImg from '../../assets/images/certificates/emty.jpg';

export default function MyCertificatesItem({ teacher, item }) {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };
  return (
    <div
      className={`text-center ${style.cardBg} align-self-stretch`}
      style={{
        maxWidth: '235px',
        maxHeight: '405px',
        marginRight: '15px',
        margin: 'auto',
        cursor: 'pointer',
      }}
    >
      <div className={style.heart}>
        {teacher?.is_favorite ? <i style={{ color: 'red', fontSize: '20px' }} className="fa fa-heart"></i> : <img width="20px" src={heart} />}
      </div>
      <div onClick={handleShow1} className={style.imageBox1}>
        {teacher?.photo?.path && (
          // <img
          //   width="238"
          //   height="290"
          //   src={`${teacher?.photo?.path}`}
          //   className="img-fluid attachment-large size-large"
          //   alt=""
          //   loading="lazy"
          //   style={{ borderRadius: "10px" }}
          // />
          <div
            className={`${style.teacherBg}`}
            style={{
              maxWidth: '270px',
              maxHeight: '290px',
              background: `url(${bgImg})`,
            }}
          ></div>
        )}
        {teacher?.logo && <img className={`position-absolute ${style.teacherLogo}`} src={teacher?.logo?.path} />}
      </div>

      <div className={`icon-box-content mt-3 ${style.footer}`}>
        <div className={`d-flex ${style.progressWrapper}`}>
          <div className={style.time}>100%</div>
          <div className={` ${style.progress}`}>
            <div className={style.progressLine1} style={{ width: '100%' }}></div>
            <div className={style.progressLine} style={{ width: '100%' }}></div>
          </div>
        </div>
        <div className=" text-left">
          <div
            onClick={handleShow1}
            style={{
              maxHeight: '50px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className=" size-default btn-hover-primary"
          >
            {item?.name}
          </div>
          <p className="text-muted">{teacher?.categories ? teacher?.categories[0]?.name : ''} </p>
        </div>
      </div>
      <Modal size="lg" show={show1} onHide={handleClose1} centered>
        <Modal.Body style={{ background: '#14161A', overflow: 'hidden' }}>
          <CertificateModal teacher={teacher} item={item} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
