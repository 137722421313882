import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ItemCW from '../../components/ContinuesWatching/ItemCW';
export default function SavedClasses() {
  return (
    <div>
      <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
        <Container>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-50">
                  <h4 className="main-title channel-logo w-100 text-left">Tamamlanan Dersler</h4>
                  <p className="w-100 text-left mt-2">You can view the classes you have completed.</p>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between mr-3">
                  <div to="/profile" className="btn btn-outline-light">
                    Son İzlediklerin
                  </div>
                </div>

                <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link>
              </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="3" className="mb-4">
              <ItemCW
                vMax="285px"
                hMax="505px"
                img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
              />
            </Col>
            <Col sm="3">
              <ItemCW
                vMax="285px"
                hMax="505px"
                img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
              />
            </Col>
            <Col sm="3">
              <ItemCW
                vMax="285px"
                hMax="505px"
                img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
              />
            </Col>
            <Col sm="3">
              <ItemCW
                vMax="285px"
                hMax="505px"
                img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
              />
            </Col>
            <Col sm="3">
              <ItemCW
                vMax="285px"
                hMax="505px"
                img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
