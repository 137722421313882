import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import VideoItem1 from '../VideoComponents/VideoItem1';
import { getClassrooms } from '../../Services/Classroom';
import ContentLoader from 'react-content-loader';
import { isMobile } from '../../utils/isMobil';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function Sliders(props) {
  const [skeletoon, setSkeleton] = useState([1, 2, 3, 4, 5]);
  const { isLoading, data } = useQuery(['Discover', props?.q ? props?.q : '/sorted/most_watched'], () =>
    getClassrooms(props?.q ? props?.q : '/sorted/most_watched')
  );

  // if (isLoading) return "Yükleniyor...";

  // if (error) return "An error has occurred: " + error.message;

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Container>
      <Row>
        <Col sm="12" className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-item-center flex-wrap">
              <h4 className="main-title channel-logo mt-0">{props.headerTitle}</h4>
            </div>

            <div className="d-flex align-items-center justify-content-between fs-small">
              <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                <div id={props.prev} className="">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div id={props.next} className="">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>

              <Link className=" iq-view-all" to={props?.headerTitle === 'Anne-Babalara Özel' ? 'ebeveynlikcocuk-gelisimi' : '/kategoriler'}>
                Tümü
              </Link>
            </div>
          </div>
          <div id="favorites-contens">
            <Swiper
              navigation={{
                prevEl: `#${props.prev}`,
                nextEl: `#${props.next}`,
              }}
              breakpoints={{
                320: { slidesPerView: 2.1 },
                400: { slidesPerView: 2.1 },
                430: { slidesPerView: 2.1 },
                600: { slidesPerView: 3 },
                991: { slidesPerView: 4 },
                1400: { slidesPerView: 4 },
              }}
              loop={false}
              slidesPerView={4}
              spaceBetween={20}
              as="ul"
              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
            >
              {isLoading &&
                !isMobile() &&
                skeletoon.map((index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ContentLoader
                        speed={1}
                        width={280}
                        height={500}
                        // viewBox="0 0 400 460"
                        backgroundColor="#3b3b3b"
                        foregroundColor="#292929"
                        {...props}
                        className="mr-3"
                        onContextMenu={preventDefault}
                        onClick={preventDefault}
                      >
                        <rect x="0" y="0" rx="2" ry="2" width="280" height="500" />
                      </ContentLoader>
                    </SwiperSlide>
                  );
                })}
              {data?.data?.map((item, index) => {
                return (
                  <SwiperSlide key={index} as="li" onContextMenu={preventDefault} onClick={preventDefault}>
                    <VideoItem1 vMax="285px" hMax="555px" item={item} minH="555px" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
