import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import style from './style.module.css';
import FeatureItem from './FeatureItems';
import play from '../../assets/icons/features/play.svg';
import flexible from '../../assets/icons/features/flexible.svg';
import lessons from '../../assets/icons/features/lessons.svg';
import interactive from '../../assets/icons/features/interactive.svg';
import saveable from '../../assets/icons/features/saveable.svg';
import identification from '../../assets/icons/features/identification.svg';
import performance from '../../assets/icons/features/performance.svg';
import certificate from '../../assets/icons/features/certificate.svg';

const Features = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [features, setFeatures] = useState([
    {
      id: 1,
      type: 1,
      title: 'İhtiyaca yönelik, etkili bir eğitim deneyimi',
      text: 'Türkiye’nin alanında en iyi uzmanlarının hem nokta atışı hem akılda kalıcı eğitim içeriklerine ulaşın. ',
      img: play,
    },
    {
      id: 2,
      type: 1,
      title: 'Esnek öğrenme formatı',
      text: 'İster masaüstünde ister mobil uygulamada; her zaman, her yerde ve kendi hızınızda öğrenin.',
      img: flexible,
    },
    {
      id: 3,
      type: 1,
      title: 'Beceri kazandıran dersler',
      text: 'Pratik ve uygulamalı derslerle teorik bilgiyi, gerçek hayatta uygulanabilir bilgi ve becerilere dönüştürün.',
      img: lessons,
    },
    {
      id: 4,
      type: 1,
      title: 'İnteraktif çalışma ',
      text: 'Zaman kodlu not alma sistemiyle interaktif bir eğitim deneyimi yaşayın. ',
      img: interactive,
    },
    {
      id: 5,
      type: 2,
      title: 'İndirilebilir raporlar ',
      text: 'Takımınızın eğitime katılım - içerik izleme sürelerini raporlayın ve arşivinize katın.',
      img: saveable,
    },
    {
      id: 6,
      type: 2,
      title: 'Eğitim tanımlama ve atama',
      text: 'Ekip üyelerinize onları geliştirecek, her birine kişiye özel beceri kazandıracak eğitim içeriği atayın. ',
      img: identification,
    },
    {
      id: 7,
      type: 2,
      title: 'Performans takibi',
      text: 'Çalışanlarınızın her bir eğitimdeki gelişim-performans sürecini takip edin. ',
      img: performance,
    },
    {
      id: 8,
      type: 2,
      title: 'Sertifika sağlama',
      text: 'Ekip üyelerinizi, kariyer yolunda bilgi beceri gelişimlerini gösteren sertifikalarla motive edin.',
      img: certificate,
    },
  ]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  useEffect(() => {
    const filtered = features.filter((item) => item.type == selectedTab);
    setSelectedFeatures(filtered);
  }, [selectedTab]);
  return (
    <div>
      <Container className="text-center">
        <Row>
          <Col sm="12">
            <div className="text-primary">ÖZELLİKLER</div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm="12">
            <h4 className="">WISE & RISE İLE NASIL ÇALIŞACAKSINIZ?</h4>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm="12">
            <div className="d-flex justify-content-center">
              <div className={`${style.tab} ${selectedTab == 1 ? style.active : ''}`} onClick={() => setSelectedTab(1)}>
                Kullanıcılar için
              </div>
              <div className={`${style.tab} ${selectedTab == 2 ? style.active : ''}`} onClick={() => setSelectedTab(2)}>
                Yöneticiler için
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {selectedFeatures?.map((feature) => {
            return (
              <Col sm="6">
                <FeatureItem item={feature} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
export default Features;
