import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './style.css';
import addIcon from '../../assets/icons/icons-plus-bg.png';
import ItemCard from './ItemCard';
import { Link } from 'react-router-dom';
import { getProfiles } from '../../Services/Profile';
import { useAuth } from '../../contexts/AuthContext';

export default function WhoIsWatch() {
  const { refresh } = useAuth();
  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    getProfiles()
      .then((data) => {
        console.log(data);
        setProfiles(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, [refresh]);

  return (
    <>
      <section className=" sign-in-page1 slick-bg ws-bg-credit-card ">
        <Container className="mt-5 ">
          <Row className="mb-5 pasition-relative">
            <Col sm="12" className="text-center ">
              <h1 className="h1">Kim İzliyor?</h1>
            </Col>
          </Row>
          <Row className="mt-5 text-center justify-content-center m-auto profiles-w pasition-relative" style={{ width: '85%' }}>
            {profiles?.map((item, index) => {
              return <ItemCard key={index} profile={item} />;
            })}

            {profiles?.length < 4 && (
              <Col xs="12" sm="6" md="4" lg="3" className="text-center mt-3">
                <Link to="./add-profile" className="ws-item-wrapper text-center">
                  <div className="ws-item">
                    <img src={addIcon} alt="Add Profile" />
                  </div>
                  <div className="mt-4 btn-hover-primary">Profil Ekle</div>
                </Link>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}
