import React from 'react';
import { Container, Row, Col, Breadcrumb, Form, Button } from 'react-bootstrap';
import AboutFooter from '../AboutUs/components/AboutFooter';
import { Link } from 'react-router-dom';
import { isMobile } from '../../utils/isMobil';

export default function ContactUs() {
  return (
    <>
      <div className="mb-5 " style={{ paddingTop: '70px', paddingBottom: '3%' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row>
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-center text-md-left  iq-breadcrumb-two my-3 py-3 my-md-4 py-md-4">
                  <h1 className="h1 title">İletişim</h1>
                  <div className="red-line w-50"></div>
                  <span>
                    <Link to="/">Anasayfa</Link> / <span className="text-primary">İletişim</span>
                  </span>
                  {/* <span>
                    <Link to="/">Anasayfa</Link> / <span className="text-primary">İletişim</span>
                  </span> */}
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <AboutFooter classname={'contact'} />
    </>
  );
}
