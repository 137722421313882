import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../../components/PageTitle';
import { useInfiniteQuery } from '@tanstack/react-query';
import Categorieslist from './components/Categorieslist';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import VideoItem1 from '../../components/VideoComponents/VideoItem1';
import { getClassroomFilter, getClassroomsQ } from '../../Services/Classroom';
import ContentLoader from 'react-content-loader';
import HomeFooterComponent from '../Home/components/HomeFooterCom';
import { useAuth } from '../../contexts/AuthContext';
import { useApi } from '../../contexts/RequestContext';
import Pagination from '../../components/UI/pagination/Pagination';

export default function Category() {
  const { slug } = useParams();
  const { inLogin } = useAuth();
  const { selectCategory } = useApi();
  const [skeletoon, setSkeleton] = useState([1, 2, 3, 4, 5, 6]);
  const [price, setPrice] = useState([{ price: '59.9' }, { price: '99' }]);

  const { status, data, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['AllClasses', slug !== 'kategoriler' ? selectCategory?.id : ''],
    getClassroomFilter,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.meta?.current_page < lastPage?.meta?.last_page) return lastPage?.meta?.current_page + 1;
      },
    }
  );
  const isMobile = () => {
    return window.innerWidth <= 768; // Örnek bir değer, mobil cihazları temsil eder
  };
  useEffect(() => {
    if (isMobile()) {
      const timeoutId = setTimeout(() => {
        const categoryContent = document.getElementById('category-content');
        if (categoryContent) {
          categoryContent.scrollIntoView({ behavior: 'smooth' });
        }
      }, 700);

      return () => clearTimeout(timeoutId);
    }
  }, [selectCategory]);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Wise&Rise',
        item: 'https://www.wisenrise.com',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: selectCategory?.name,
        item: window.location.href,
      },
    ],
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
      <Row>
        <Col sm="12">
          <PageTitle
            title={slug !== 'kategoriler' ? selectCategory?.name : 'Tüm Kategoriler'}
            subTitile={'Uzman İsimler, Yüzlerce Konu Başlığı, Her Ay Yeni İçerikler'}
          />
        </Col>
      </Row>
      <Container>
        <Row className="mt-5">
          <Col xs="12" sm="12" md="3">
            <Categorieslist params={slug} />
          </Col>
          <Col xs="12" sm="12" md="9" id="category-content">
            <Row style={{ width: '100%' }}>
              {status == 'loading' &&
                skeletoon.map((item, index) => {
                  return (
                    <Col xs="6" sm="6" md="4" className="mb-5" key={index}>
                      <ContentLoader
                        speed={1}
                        width={200}
                        height={300}
                        // viewBox="0 0 400 460"
                        backgroundColor="#3b3b3b"
                        foregroundColor="#292929"
                        className="m-auto"
                      >
                        <rect x="0" y="0" rx="2" ry="2" width="180" height="500" />
                      </ContentLoader>
                    </Col>
                  );
                })}
            </Row>

            <Row>
              {data?.pages.map((page) => (
                <React.Fragment key={page.nextId}>
                  {page?.data?.map((item) => (
                    <Col key={item.id} xs="6" sm="6" md="4" className="mb-5">
                      <VideoItem1 vMax="250px" hMax="505px" item={item} minH="450px" />
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </Row>
            <Pagination hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </Col>
        </Row>
      </Container>

      {!inLogin && (
        <div className="mb-5 mt-5 d-none d-sm-block">
          <Container>
            <HomeFooterComponent price={price} type={'classes'} />
          </Container>
        </div>
      )}
    </div>
  );
}
