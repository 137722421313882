import { useState, useEffect } from 'react';
//router
import LayoutsRoute from './router/layouts-route';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//scss files
import './assets/css/bootstrap.css';
import './assets/css/typography.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

function App() {
  const [loding, setLoading] = useState(true);
  const spinner = document.getElementById('loading');
  useEffect(() => {
    setTimeout(() => {
      if (spinner) {
        spinner.style.display = 'none';
        setLoading(false);
      }
    }, 4000);
    return () => {};
  }, []);

  return (
    !loding && (
      <div className="App ">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <LayoutsRoute />
      </div>
    )
  );
}

export default App;
