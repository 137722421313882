import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './style.css';
import { useApi } from '../../contexts/RequestContext';
import { useAuth } from '../../contexts/AuthContext';

export default function VideoTitle({ item, news, one }) {
  const { videoSet, teacherSet } = useApi();
  const { inLogin } = useAuth();
  const history = useHistory();

  const lastWatch = () => {
    for (let i = item?.videos?.length - 1; i >= 0; i--) {
      if (item?.videos[i]?.current_duration !== '00:00:00') return i;
    }
    return 0;
  };

  const open = () => {
    console.log(item);
    if (inLogin) {
      teacherSet(item?.teacher);
      videoSet(item);
      history.push(`/${item?.teacher?.slug}/${item?.slug}/${item?.videos[lastWatch()]?.slug}`);
    } else {
      history.push(`/${item?.teacher?.slug}/${item?.slug}`);
    }
  };

  const getTitle = (text) => {
    if (window.screen.width < 720) return `${text?.slice(0, 15)}  ${text?.length > 20 ? '...' : ''}`;
    else return text;
  };

  const formatName = (name) => {
    if (!name) return '';
    return name.replace(/\bVE\b/g, 've');
  };

  return (
    <div className="d-flex flex-column justify-content-between align-items-center w-100 ws-title">
      {news && <div className="news">Yeni</div>}
      <div className="text-center d-flex align-items-center justify-content-end flex-column">
        <Link to={`/${item?.teacher?.slug}/${item?.slug}`} title={item?.name} className="ws-card-title">
          {item?.teacher?.logo ? (
            <img src={item?.teacher?.logo?.path} alt={`${item?.teacher?.name} logosu`} style={{ width: '70%' }} />
          ) : (
            <h3>{formatName(item?.teacher?.name)}</h3>
          )}
        </Link>
        <div className="mt-auto pt-3 pt-md-0">
          <div className="ws-card-line"></div>
          <div className="d-flex justify-content-center text-center w-100 px-3 mb-1">{getTitle(formatName(item?.name))}</div>
        </div>
      </div>
      {!one && (
        <div className="d-flex flex-column align-items-stretch" style={{ width: '90%' }}>
          <Link
            to={`/${item?.teacher?.slug}/${item?.slug}`}
            title={item?.name}
            // onClick={open}
            role="button"
            className="btn btn-hover mb-3 overflow-hidden"
          >
            <i className="fa fa-play mr-1" aria-hidden="true"></i>
            Şimdi İzle
          </Link>
          <Link to={`/${item?.teacher?.slug}/${item?.slug}`} title={item?.name} role="button" className="btn btn-outline-light overflow-hidden">
            İncele
          </Link>
        </div>
      )}
    </div>
  );
}
