import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function OtherClasses() {
  return (
    <div>
      <Row className="align-items-center">
        <Col sm="12">
          <div className="text-center iq-breadcrumb-two">
            <Link to="/kategoriler">
              <h6 className=" text-primary mb-3 text-uppercase">İLGİNİ ÇEKEBİLECEK</h6>
            </Link>

            <h3 className="title mb-3">Diğer Eğitimler </h3>
            <p className="m-auto " style={{ maxWidth: '660px', fontSize: '18px' }}>
              En iyilere sınırsız erişim seni bekliyor
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
