import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  useEffect(() => {
    document.title = '404 - Page Not Found';
    document.querySelector('meta[name="description"]').setAttribute('content', 'The page you are looking for does not exist.');
    if (window.location.pathname !== '/404') {
      window.history.replaceState(null, null, '/404');
    }
  }, []);

  return (
    <div className="mb-5 " style={{ paddingTop: '70px', paddingBottom: '3%' }}>
      <Container style={{ background: '#14161a' }} fluid>
        <Container>
          <Row>
            <Col sm="12">
              <nav aria-label="breadcrumb" className="text-center text-md-left  iq-breadcrumb-two my-3 py-3 my-md-4 py-md-4">
                <h1 className="h1 title">404 - Sayfa Bulunamadı</h1>
                <div className="red-line w-50"></div>
                <span>
                  <Link to="/">Anasayfa</Link>
                </span>
                {/* <p>Üzgünüz, aradığınız sayfa mevcut değil.</p> */}
                {/* <span>
                    <Link to="/">Anasayfa</Link> / <span className="text-primary">İletişim</span>
                  </span> */}
              </nav>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default NotFoundPage;
