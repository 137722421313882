import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useInfiniteQuery } from '@tanstack/react-query';
import MyCertificatesItem from '../../components/MyCertificatesItem';
import Pagination from '../../components/UI/pagination/Pagination';
import { getClassroomsComplated } from '../../Services/Classroom';
import { useAuth } from '../../contexts/AuthContext';

export default function MyCertificatesPage() {
  const { profile } = useAuth();
  const { status, data, error, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['certificates', profile?.id],
    getClassroomsComplated,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.meta?.current_page < lastPage?.meta?.last_page) return lastPage?.meta?.current_page + 1;
      },
    }
  );
  return (
    <div>
      <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
        <Container>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-50">
                  <h4 className="main-title channel-logo w-100 text-left">Sertifikalarım</h4>
                  <p className="w-100 text-left mt-2">Tamamladığın eğitimlerden aldığın sertifikaları görüntülebilirsin.</p>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between mr-3">
                    <div to="/profile" className="btn btn-outline-light">
                      Son İzlediklerin
                    </div>
                  </div>

                  <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link>
                </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            {status === 'loading' ? (
              <p>Yükleniyor...</p>
            ) : status === 'error' ? (
              <span>Hata oluştu: {error.message}</span>
            ) : (
              <>
                {data?.pages.map((page) => (
                  <React.Fragment key={page.nextId}>
                    {page?.data?.map((item, index) => (
                      <Col key={index} xs="12" sm="6" md="4" lg="3" className="mb-4 align-self-stretch">
                        <MyCertificatesItem teacher={item?.teacher} item={item} />
                      </Col>
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
          </Row>

          <Row>
            <Col>
              <Pagination hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
