import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import { gsap } from 'gsap';
import style from './style.module.css';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { useAuth } from '../../../contexts/AuthContext';
import { useApi } from '../../../contexts/RequestContext';
import VideoModal from '../../../components/VideoModal';
import NotesModal from '../../../components/MyNotes/NotesModal';
import logo_t from '../../../assets/images/bg/egitmen_logo_w.png';
import { isMobile } from '../../../utils/isMobil';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: '',
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '');

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const PageTitle = (props) => {
  const { inLogin } = useAuth();
  const { videoSet } = useApi();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const animationInit = () => {
    if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {
      const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]');
      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };
  const lastWatch = () => {
    for (let i = props?.item?.videos.length - 1; i >= 0; i--) {
      if (props?.item?.videos[i]?.current_duration !== '00:00:00') return i;
    }
    return 0;
  };
  const open = (item) => {
    videoSet(item);
    history.push(`/${item?.teacher?.slug}/${item?.slug}/${item?.videos[lastWatch()]?.slug}`);
  };

  const getDesc = (desc) => {
    return isMobile() ? `${desc?.slice(0, 80)}...` : desc;
  };

  return (
    <>
      <Swiper
        navigation={{
          prevEl: '#prev5',
          nextEl: '#next5',
        }}
        pagination={{
          clickable: true,
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={false}
        id="home-slider"
        className="slider m-0 p-0 pb-5"
      >
        <SwiperSlide
          className={`slide slick-bg slick-title-bg ${isMobile() ? '' : 'slick-bg111'} `}
          style={{
            background: `url(${!isMobile() ? props?.item?.cover?.path : props?.item?.teacher?.photo?.path})`,
          }}
        >
          <Container className="position-relative mt-5 pt-5 pt-md-0 mt-md-0 h-100">
            <div className="slider-inner mt-5 pt-5 pt-md-0 mt-md-2">
              <Row className="align-items-center mt-5 iq-ltr-direction h-100 ">
                <Col xs="12" md="8">
                  <div className="w-100 d-flex flex-column align-items-center mt-0 pt-0 mt-md-5 pt-md-5 text-center">
                    {props?.item?.teacher?.logo ? (
                      <div className="d-flex justify-content-center w-75">
                        <img
                          style={{ maxWidth: '400px', minWidth: '200px' }}
                          className="big-title"
                          data-iq-gsap="onStart"
                          data-iq-position-x="-200"
                          src={props?.item?.teacher?.logo?.path}
                        />
                      </div>
                    ) : (
                      <h1 className=" slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">
                        {props?.item?.teacher?.name}
                      </h1>
                    )}

                    <div className="wd-card-line"></div>

                    <div
                      className="d-flex mt-1 mt-md-2 text-light justify-content-center text-uppercase"
                      data-iq-gsap="onStart"
                      data-iq-position-x="-200"
                      data-iq-delay="-0.5"
                      style={{ width: '300px', height: '34px' }}
                    >
                      {props?.item?.name}
                    </div>
                    <div className={`${style.certificateOpp} mt-4`}>Sertifika İmkânı</div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
                      <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" className="my-4">
                        {getDesc(props?.item?.description)}{' '}
                      </p>
                    </div>
                    {inLogin && (
                      <div className="d-flex align-items-center r-mb-23 " data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                        <div onClick={() => open(props?.item)} className="btn btn-hover">
                          İzlemeye {props?.item?.completion_rate > 0 ? 'Devam Et' : 'Başla'}
                        </div>
                        <div className="btn btn-link" onClick={handleShow1}>
                          Notlarım
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col md="4" className="trailor-video text-center d-none d-md-block">
                  <div onClick={() => handleShow()} className="video-open playbtn">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="80px"
                      height="80px"
                      viewBox="0 0 213.7 213.7"
                      enableBackground="new 0 0 213.7 213.7"
                      xmlSpace="preserve"
                    >
                      <polygon
                        className="triangle"
                        fill="none"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="73.5,62.5 148.5,105.8 73.5,149.1 "
                      />
                      <circle
                        className="circle"
                        fill="none"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        cx="106.8"
                        cy="106.8"
                        r="103.3"
                      />
                    </svg>
                    <span className="w-trailor">Tanıtımı İzle</span>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </SwiperSlide>
      </Swiper>
      <Modal show={show} onHide={handleClose} centered style={{ background: '#000', border: '0px #000' }} size="xl">
        <Modal.Body style={{ background: '#000', width: '100%', border: '0px #000' }}>
          <VideoModal handleClose={handleClose} item={props?.item} />
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={show1} onHide={handleClose1} centered>
        <Modal.Body style={{ background: '#14161A' }}>
          <NotesModal handleClose={handleClose1} item={props?.item} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PageTitle;
