import React, { useEffect, useState } from 'react';
import NoteItemModal from '../../../components/MyNotes/NoteItemModal';
import { getNotes } from '../../../Services/Note';
import NoteItemHeader from './NoteItemHeader';

import style from './style.module.css';
const NotesModal = ({ handleClose, item }) => {
  const [refresh, setRefresh] = useState(false);
  const handleChange = () => {
    setRefresh(!refresh);
  };
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getNotes('0', `?video_id=${item?.id}&`).then((res) => {
      setNotes(res?.data);
    });

    return () => {};
  }, [refresh, item]);
  return (
    <div>
      <NoteItemHeader type={'modal'} handleClose1={handleClose} item={item} />
      <div className={`${style.scroll}`}>
        {notes?.map((note) => {
          return <NoteItemModal key={note?.id} note={note} item={item} handleChange={handleChange} />;
        })}
      </div>
    </div>
  );
};

export default NotesModal;
