import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from '@tanstack/react-query';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import ItemCW from './ItemCW';
import { Col, Row } from 'react-bootstrap';
import { getAssignedClassrooms, getClassrooms, getClassroomsWatching } from '../../Services/Classroom';
import { useAuth } from '../../contexts/AuthContext';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function SliderCW({ next, prev, query }) {
  const { profile } = useAuth();
  const [classroom, setClassroom] = useState([]);
  const { isLoading, error, data } = useQuery([`Discover:${query}`], () =>
    query == 'assigned-classrooms'
      ? getAssignedClassrooms(profile?.id)
      : query == 'watching'
      ? getClassroomsWatching(profile?.id)
      : getClassrooms(query)
  );
  useEffect(() => {
    setClassroom([]);
    if (data) setClassroom(data?.data);
    return () => {};
  }, [data]);
  if (isLoading) return 'Yükleniyor...';

  if (error) return 'An error has occurred: ' + error.message;
  return (
    <section id="home" className="">
      <Swiper
        navigation={{
          prevEl: `#${prev}`,
          nextEl: `#${next}`,
        }}
        breakpoints={{
          320: { slidesPerView: 2 },
          550: { slidesPerView: 2 },
          991: { slidesPerView: 3 },
          1400: { slidesPerView: 4 },
        }}
        loop={false}
        spaceBetween={30}
        as="ul"
        className="favorites-slider list-inline row p-0 m-0 "
      >
        {data?.data?.length < 1 && (
          <div className="d-flex justify-content-center w-100 mt-5">
            <div>
              <button disabled={true} className="btn  btn-outline-light">
                Eğitimler bulunmandı
              </button>
            </div>
          </div>
        )}
        {data?.data?.map((item, index) => {
          return (
            <SwiperSlide as="li" key={index}>
              <ItemCW vMax="285px" hMax="505px" item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}
