import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import './style.css';
import { useAuth } from '../../contexts/AuthContext';
import FooterCard from './components/footerCard';
import iconPlus from '../../assets/icons/IconPlus.png';
import { cancelAccount, getOrders, getPlans } from '../../Services/AuthApi';
import moment from 'moment/moment';
import { notify, notifyError } from '../../components/AlartNotify/AlertNotify';
import { isMobile } from '../../utils/isMobil';

export default function PracingPlan() {
  let history = useHistory();
  const [orders, setOrders] = useState([]);
  const { user, logout, inLogin } = useAuth();
  const [isMonthly, setIsMonthly] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState([{ price: '' }, { price: '' }]);
  const [yearPrice, setYearPrice] = useState([{ price: '' }, { price: '' }]);
  function del(id) {
    Swal.fire({
      title: 'Onayla!',
      text: 'Üyeliğiniz iptal edilecektir. İptal işleminden sonra tekrar aynı üyelik bilgilerinizle giriş yaptığınızda otomatik aktifleştirilecektir.',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, İptal Et',
      cancelButtonText: 'Vazgeç',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        disabledAccount();
      } else if (result.isDenied) {
      }
    });
  }

  useEffect(() => {
    getPlans('Monthly').then((res) => {
      setMonthlyPrice(res.data);
    });
    getPlans('Yearly').then((res) => {
      setYearPrice(res.data);
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (inLogin) {
      getOrders().then((res) => {
        setOrders(res?.data);
      });
    }

    return () => {};
  }, []);
  const onSubmit = (type) => {
    if (!inLogin) history.push('sign-up');
  };
  const disabledAccount = () => {
    cancelAccount()
      .then(() => {
        notify('Üyelik iptal işleminiz Gerçekleşmiştir.');
        logout();
      })
      .catch((err) => {
        notifyError(err?.response?.data?.message);
      });
  };

  return (
    <>
      <div className=" " style={{ paddingTop: '70px', paddingBottom: '3%' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row>
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-center text-md-left  iq-breadcrumb-two my-3 py-3 my-md-4 py-md-4">
                  {!isMobile() && <span className="text-primary text-uppercase">Planlar</span>}
                  <h1 className="h1 title">Üyelik Planları</h1>
                  <div className="red-line w-50"></div>
                  {isMobile() && <span className="text-primary text-uppercase">Planlar</span>}
                  {/* <span>
                    <Link to="/">Anasayfa</Link> / <span className="text-primary">İletişim</span>
                  </span> */}
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <section className="plan-visible">
        <Container>
          <Row className="justify-content-center align-items-center " style={{ marginTop: '5%' }}>
            <Col lg="12" md="12" className="align-self-center">
              <div className="text-center ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    {/* <h6 className="mb-3 text-primary text-center">Adım 3/4</h6> */}
                    <h3 className="mb-3 text-center your-plan-text">12 AY SINIRSIZ ERİŞİM</h3>

                    {!inLogin && (
                      <div className="isMonthly">
                        <div className={`${isMonthly && 'isMonthlyActive'}`} style={{ cursor: 'pointer' }} onClick={() => setIsMonthly(true)}>
                          Peşin
                        </div>
                        <div className={`${!isMonthly && 'isMonthlyActive'}`} style={{ cursor: 'pointer' }} onClick={() => setIsMonthly(false)}>
                          Taksitli
                        </div>
                      </div>
                    )}
                  </div>
                  <Row className={`mt-5  d-flex justify-content-center`}>
                    {(!inLogin || user?.plan_type != 'Duo') && (
                      <Col sm="12" md="6" className="p-0 pt-4 pb-3">
                        <div
                          className="ws-card-left text-left"
                          style={{
                            borderColor: `${inLogin && user?.plan_type != 'Duo' ? '#be161a' : ''}`,
                          }}
                        >
                          <div className="text-primary text-uppercase my-3">
                            {isMonthly ? 'Peşin' : 'Taksit'}
                            <span className="text-capitalize"> (Yıllık)</span>
                          </div>
                          <div className="text-white my-3">1 Hesap - 4 Profil</div>
                          <h4 className="my4">
                            {/* {!inLogin && (isMonthly ? monthlyPrice[0]?.price : yearPrice[0]?.price)} */}
                            {!inLogin && (isMonthly ? yearPrice[3]?.name : yearPrice[2]?.name)}{' '}
                            <span>{!inLogin && !isMonthly && '(Toplam: ' + yearPrice[2]?.price + ' TL)'}</span>
                            {inLogin && user.subscription?.plan?.price}{' '}
                            {/* <span style={{ fontSize: '25px', color: '#fff' }}>₺ {!isMonthly ? '/Ay' : ''}</span> */}
                          </h4>
                          <div className="my-3  d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-light">9 Kategori+20’den fazla içerik</span>
                          </div>
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">Her Ay Yeni İçerikler</span>
                          </div>
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">Her Ay Size Özel Bonus İçerikler</span>
                          </div>
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</span>
                          </div>
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">İzlerken Not Alma Özelliği</span>
                          </div>
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">Mobil Cihazdan Podcast Dinleme Özelliği</span>
                          </div>
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</span>
                          </div>

                          {/* <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">İstediğin Zaman İptal Etme Seçeneği</span>
                          </div> */}
                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">Tek Fiyat 12 Ay Sınırsız Erişim İmkanı</span>
                          </div>

                          <div className="my-3 d-flex align-items-start">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">4 Profil İle Kullanım İmkanı</span>
                          </div>

                          <div
                            className="d-flex justify-content-center align-items-center mt-3 w-100"
                            data-iq-gsap="onStart"
                            data-iq-position-y="80"
                            data-iq-delay="0.8"
                          >
                            {!inLogin && (
                              // <div className="btn btn-link btn-outline-light w-100 " onClick={() => onSubmit('Individual')}>
                              //   Seç
                              // </div>
                              <div className="btn btn-hover w-100" onClick={() => onSubmit('Duo')}>
                                SATIN AL
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          {inLogin && (
            <div className="btn btn-hover mt-5" onClick={() => del('')}>
              İptal Et
            </div>
          )}
        </Container>
      </section>

      {!inLogin && (
        <section className="mt-5 d-none d-md-block">
          <Container>
            <Row>
              <Col sm="12" className="p-0">
                <div className="ws-card-right d-flex flex-wrap flex-sm-nowrap justify-content-between text-left">
                  <div className="w-100 w-sm-75">
                    <h3 className="my-1">Wise&Rise İş Dünyasında!</h3>
                    <p>
                      Wise&Rise ile kurumunuzda bir öğrenme kültürü oluşturun. Grup indirimleri ve daha fazla bilgi için bizimle iletişime geçin.{' '}
                    </p>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="/iletisim" className="btn btn-hover ">
                      BİZİMLE İLERLE{' '}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {inLogin && (
        <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
          <Container>
            <Row>
              <Col sm="12">
                <div className="sign-user_card " style={{ backgroundColor: '#14161A', borderRadius: '10px' }}>
                  <Row>
                    <Col lg="12" className="device-margin p-5">
                      <div className="w-75">
                        <h4 className="main-title w-100 text-left">Ödeme Geçmişi</h4>
                        <p className="w-100 text-left mt-2">Tüm son işlemler listesi.</p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="overflow-scroll-x">
                      <table className="table table-borderless text-left">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Başlangıç Tarihi</th>
                            <th scope="col">Bitiş Tarihi</th>
                            <th scope="col">Durum</th>
                            <th scope="col">Fiyat</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders?.map((item, index) => {
                            return (
                              <tr key={item?.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{moment(item?.start_period)?.format('DD/MM/YYYY')}</td>
                                <td>{moment(item?.end_period).format('DD/MM/YYYY')}</td>
                                <td>{item?.status === 'SUCCESS' ? 'Ödendi' : 'Bekleyen Ödeme'}</td>
                                <td>{item?.price}₺</td>
                                <td className="text-primary"></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {!inLogin && !isMobile() && (
        <section className="mt-5">
          <Container>
            <Row className="text-center">
              <Col>
                <FooterCard />
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
}
