import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getPlans } from '../../Services/AuthApi';
import { Link } from 'react-router-dom';

export default function OnlineConditions() {
  const [price, setPrice] = useState([]);
  useEffect(() => {
    getPlans('Yearly')
      .then((res) => {
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);
  return (
    <div>
      <div className=" " style={{ paddingTop: '80px', paddingBottom: '60px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Mesafeli Sözleşme</h1>
                  <span>
                    Anasayfa / <span className="text-primary">Mesafeli Sözleşme</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                <h5>ÜYELİK VE ÇEVRİMİÇİ (ONLINE) ABONELİK HİZMETİ SATIN ALIMINA İLİŞKİN MESAFELİ SÖZLEŞME</h5>
                <br />
                <div className="mb-3">
                  <h5 className="mb-3">MADDE 1 – GİRİŞ </h5>
                  <p className="my-3">
                    1.1. Okumakta olduğunuz sözleşme Veranova Medya ve Dijital Yayın Hizmetleri Anonim Şirketi’nin (<b>“Veranova Medya”</b> veya
                    <b>“SATICI”</b> veya <b>“Sağlayıcı”</b> ) sahibi olduğu web sitesi, mobil uygulama ve Wise&Rise isimli dijital platform (birlikte
                    <b> “Wise&Rise”</b>) aracılığıyla sunacağı hizmetlerden faydalanmak isteyen siz değerli üye adayına (<b>“ALICI”</b>) yönelik
                    kuralları belirlemekte olup onayınıza sunulmaktadır.
                    <br />
                    <br />
                    1.2. 6502 sayılı Tüketicinin Korunması Hakkında Kanun, Mesafeli Sözleşmelere Dair Yönetmelik ve Abonelik Sözleşmeleri
                    Yönetmeliğine uygun şekilde hazırlanan işbu Üyelik ve Çevrimiçi (Online) Abonelik Hizmeti Satın Alımına İlişkin Mesafeli Sözleşme
                    bundan böyle kısaca “Mesafeli Sözleşme’’ olarak anılacaktır.
                    <br />
                    <br />
                    1.3. Lütfen Mesafeli Sözleşme’nin tüm hükümlerini okuyup anladığınızdan emin olduktan sonra onaylayınız. Bunun için yeterli
                    süreniz bulunmaktadır.
                    <br />
                    <br />
                    1.4. Wise&Rise’a erişim sağlayabildiğiniz her cihazda Mesafeli Sözleşme’ye elektronik olarak onay vermeniz, tüm koşulları kabul
                    etmiş olmanız sonucunu doğurur. Bu onay işlemine “Click Wrap Yöntemi’’ olarak da adlandırılan ‘‘Tıklama Yoluyla Kabul’’ işlemi de
                    dâhildir.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3">MADDE 2 – TARAFLAR </h5>
                  <p className="mb-2">
                    <h6 className="">1.1. SATICI / SAĞLAYICI </h6>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> Ticari Unvan :</div>
                      <div> VERANOVA MEDYA VE DİJİTAL YAYIN HİZMETLERİ A.Ş.</div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> Vergi No :</div>
                      <div> 9241000351 (Beşiktaş Vergi Dairesi)</div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> MERSİS No :</div>
                      <div> 0924100035100001</div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> E-Posta: </div>
                      <div> info@wisenrise.com</div>
                    </div>
                  </p>
                  <p className="my-4">
                    <h6 className="">1.2. ALICI </h6>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> Ad-Soyadı :</div>
                      <div></div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> T.C.K.N.:</div>
                      <div> </div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> Adres:</div>
                      <div></div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: '120px' }}> E-Posta Adresi: </div>
                      <div></div>
                    </div>
                  </p>
                  <p className="mb-2">
                    <h5 className="mb-3">MADDE 3 – KONU </h5>
                    <h6 className="mb-2">Mesafeli Sözleşme; </h6>• Belirsiz süreli ve aylık olarak faturalandırılan abonelik hizmetinin (aylık plan)
                    veya 12 (on iki) ay boyunca erişim hakkı sağlayan yıllık abonelik hizmetinin (yıllık plan) ALICI tarafından satın alınması
                    karşılığında, kapsamı Mesafeli Sözleşme’nin 3 (üç) numaralı maddesinde belirtilen seçimin ALICI’nın erişimine açılması ve
                    hizmetlerin sağlanmasını,
                    <br />
                    <br />• Tarafların sahip olduğu hak ve yükümlülüklerin belirlenmesini, konu eder.
                  </p>

                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="my-3">MADDE 4 – SATIN ALINAN PLAN BİLGİLERİ VE ÖDEME YÖNTEMİ </h5>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th className="w-50 text-center">PEŞİN </th>
                        <th className="text-center">TAKSİT</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        <td>1 Hesap - 4 Profil</td>
                        <td>1 Hesap - 4 Profil</td>
                      </tr>
                      <tr>
                        <td>{price?.[3]?.name}</td>
                        <td>{price?.[2]?.name}</td>
                      </tr>
                      <tr>
                        <td>
                          <ul className="pl-4 mt-2">
                            <li> 9 Kategori+20’den fazla içerik</li>
                            <li>Her Ay Yeni İçerikler</li>
                            <li>Her Ay Size Özel Bonus İçerikler</li>
                            <li>Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</li>
                            <li> İzlerken Not Alma Özelliği</li>
                            <li>Mobil Cihazdan Podcast Dinleme Özelliği</li>
                            <li>Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</li>
                            {/* <li> İstediğin Zaman İptal Etme Seçeneği</li> */}
                          </ul>
                        </td>
                        <td>
                          <ul className="pl-4 mt-2">
                            <li> 9 Kategori+20’den fazla içerik</li>
                            <li>Her Ay Yeni İçerikler</li>
                            <li>Her Ay Size Özel Bonus İçerikler</li>
                            <li>Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</li>
                            <li> İzlerken Not Alma Özelliği</li>
                            <li>Mobil Cihazdan Podcast Dinleme Özelliği</li>
                            <li>Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</li>
                            {/* <li> İstediğin Zaman İptal Etme Seçeneği</li> */}
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>BANKA KARTI </td>
                        <td>KREDİ KARTI</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3"> MADDE 5 – ALICI’NIN HAK VE SORUMLULUKLARI </h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.1. Onaylama: </h6>
                    ALICI; Wise&Rise bünyesinde sunulan başta Mesafeli Sözleşme olmak üzere tüm metin, form, bildirim, sözleşme gibi tüm hukuki
                    belgelerin maddelerinin her satırını okuduğunu ve tam olarak anladığını kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.2. Doğru ve Güncel Bilgi: </h6>
                    ALICI; Mesafeli Sözleşme akdetmeye ehil olduğunu, sağladığı her türlü bilginin doğru ve güncel olduğunu kabul ve beyan eder.
                    ALICI; söz konusu bilgilerin güncel ve/veya doğru olmamasından kaynaklanabilecek her türlü sorumluluğun ve yükümlülüğün bizzat
                    kendisine ait olduğunu taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.3. Reşit Olma ve Küçüğün Yararlanması: </h6>
                    ALICI; on sekiz (18) yaşından büyük olduğunu; aksinin tespit edilmesi hâlinde üyeliğinin derhâl sonlandırılacağını bildiğini kabul
                    ve beyan eder. ALICI’nın, 18 yaşından küçük kişileri Wise&Rise içeriklerinden yararlandırması velayet/vesayet sahibi kişilerin
                    onayı ve her hâlde ancak bir yetişkin gözetiminde mümkündür. Aksi haller ve bu süreçler için tüm sorumluluk ilgili ALICI’ya ait
                    olup SATICI’nın sorumlu olmadığını ALICI peşinen kabul eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.4. Şifre Koruma Taahhüdü: </h6>
                    ALICI; Wise&Rise üzerinde üyelik oluşturma işlemleri sırasında belirlediği şifresinin gizli kalması için gerekli dikkat ve özeni
                    göstereceğini, şifresini herhangi bir üçüncü şahsa açıklamayacağını, kullandırmayacağını, giriş bilgilerinin üçüncü kişilerle
                    kullandırmak amaçlı paylaşıldığının tespiti halinde üyeliğinin sonlandırılabileceğini; şifresinin yetkisiz üçüncü şahıslar
                    tarafından ele geçirildiğini öğrenmesi veya buna ilişkin şüpheli bir durum oluşması halinde derhal SATICI’ya info@wisenrise.com
                    e-posta adresi üzerinden bildirimde bulunacağını, şifresinin üçüncü şahıslar tarafından kullanılması sebebiyle doğacak zararlardan
                    SATICI’nın sorumlu olmayacağını kabul ve taahhüt eder. Şu kadar ki; Üyenin çoklu kullanıma elverişli paketi satın alması halinde,
                    paketini birlikte kullanmak üzere e-posta adresini ve şifresini paylaştığı kişilerle yapacağı bilgi paylaşımı işbu hükmün ihlali
                    teşkil etmeyecektir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.5. Satın Alınan Plan: </h6>
                    ALICI; Mesafeli Sözleşme’nin -3- numaralı kısmında yer alan üyelik türünü ve kapsamını Wise&Rise’a üye olma aşamasında kendisinin
                    tercih ettiğini, planın bu tür ve kapsama uygun şekilde oluşturulacağını bildiğini kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.6. Ödeme: </h6>
                    Tercih edilen plan dâhilindeki hizmetlerin ücreti; ‘‘ödeme ve kart bilgileri / (planlarım)’’ başlıklı sayfada bulunan ve üyelik
                    esnasında ALICI tarafından bilgisi verilen kredi / banka kartından ödeme kuruluşu Iyzico aracılığıyla tahsil edilir.{' '}
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.7. Üyeliğin Gerçekleşmesi: </h6>
                    ALICI; Mesafeli Sözleşme’nin okudum, onaylıyorum yapıldığına / butonuna tıklandığına ilişkin beyanın (dijital ortamdaki eylemin)
                    Wise&Rise’ın bilişim sistemlerine ulaşmasıyla birlikte üyelik işleminin gerçekleşmiş ve tamamlanmış olacağını kabul, beyan ve
                    taahhüt eder.{' '}
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.8. Hizmetin Yer ve Zamanı: </h6>
                    ALICI tarafından hizmetin satın alınarak üye olunmasının ardından dijital nitelikteki içeriklerin SATICI tarafından ALICI’nın
                    erişimine sunulacağı yer{' '}
                    <a className="text-danger" href="www.wisenrise.com">
                      www.wisenrise.com
                    </a>{' '}
                    ve Wise&Rise mobil uygulamasıdır. Hizmete ilişkin ödeme, ALICI’ya ait ödeme aracından kaynaklanan ‘‘bakiye yetersizliği’’ dâhil
                    her türlü teknik ve fiziki problem nedeniyle tamamlanamadığında; dijital içerikler ALICI’nın erişimine açılmayacaktır.{' '}
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.9. Ödeme Yöntem ve Bilgilerinin Saklanması: </h6>
                    ALICI; kredi/banka kartı bilgilerinin SATICI tarafından saklanmadığını; bu bilgilerin Türkiye Cumhuriyet Merkez Bankası tarafından
                    yetkilendirilen aracı ödeme kuruluşu olan ve güvenli ödeme sağlayan Iyzico tarafından saklanacağını bildiğini ve bu hususları
                    kabul ettiğini beyan ve taahhüt eder.{' '}
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.10. İnternet Erişimi ve Cihaz Gereksinimleri: </h6>
                    ALICI; Wise&Rise hizmetini kullanabilmek için, ‘‘www.wisenrise.com’’ adresinde yer alan görsel-işitsel kayıtları/videoları izleme
                    imkânı sağlayan bir internet erişimi ile cihaza sahip olması ve tercih edilen abonelik türüne uygun ödeme aracı sunması
                    gerektiğini kabul ve beyan eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.11. İçerik Yönetimi: </h6>
                    ALICI; Wise&Rise üzerinden sağlanacak içeriklere, içeriklerin çeşitliliğine, sayısına ve içeriklerde yer alan tüm bilgi, öneri ve
                    tavsiyelerin bilimsel ve/veya yorumsal doğruluğuna ilişkin olarak SATICI tarafından hiçbir taahhütte bulunulmadığını, SATICI’nın
                    Wise&Rise üzerindeki içerikleri dilediği şekilde artırma, azaltma ve değiştirme hakkına sahip olduğunu; içerik kaldırılması ya da
                    içerik eklenmesi kaynaklı ücret iadesinin mümkün olmadığını bildiğini kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.12. Çevrimdışı İçerik Politikası: </h6>
                    Veranova Medya tarafından belirlenecek bazı Wise&Rise içeriklerinin, üyeler tarafından cihaza indirmek (download) suretiyle
                    çevrimdışı (offline) olarak erişilebilmesi mümkündür. Veranova Medya, üyelerinin çevrimdışı olarak erişebilmesine imkân sağladığı
                    içeriklerine -sayılanlarla sınırlı olmamak üzere- izleme sayısı, periyodu ve erişim süresi gibi kısıtlamalar uygulayabilir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.13. Fikri Mülkiyet Hakları: </h6>
                    ALICI; Wise&Rise’a üye olmanın; Wise&Rise üzerindeki videolar, yazılı metin dokümanları üzerinde hiçbir surette lisans ya da
                    ruhsat hakkı tanımadığını kabul eder. ALICI; üyelerin erişim sağlayacağı tüm görsel-işitsel ve/veya yazılı içeriklerin, Wise&Rise
                    yazılım kodlarının tüm telif haklarının SATICI’ya veya içeriğin üretilmesine katkı sağlayan iş ortaklarına ait olduğunu bildiğini
                    beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.14. İçerik Kullanım Sınırlaması: </h6>
                    ALICI; üyelerin erişimine açılacak olan hiçbir içeriği işleme, çoğaltma, yayma, temsil, umuma iletim, kiralama ve/veya satma
                    hakkının olmadığını kabul ve beyan eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.15. Sınai Mülkiyet Hakları ve Ticari Haklar Taahhüdü: </h6>
                    ALICI; Wise&Rise sınai mülkiyet hakları, ticaret unvanı ve alan adlarının yalnızca SATICI’ya ait olduğunu bildiğini; bunların
                    taklit edilemeyeceğini, kopyalanamayacağını ve ticari/bireysel amaçla kullanılamayacağını kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.16. İşlemlerden Sorumluluk: </h6>
                    ALICI, Wise&Rise üzerinden oluşturduğu hesap aracılığıyla gerçekleşen tüm işlemlerden sorumludur. ALICI; hesabın kontrolünü
                    sürdürmek ve başkalarının hesaba erişmesini önlemek adına, hesabın şifresini kimseyle paylaşmamalıdır ve Wise&Rise’a giriş
                    yaptığı/oturum açtığı tüm cihazların kontrolünü elinde bulundurmalıdır. Şu kadar ki; ALICI’nın çoklu kullanıma elverişli paketi
                    satın alması halinde, paketini birlikte kullanmak üzere e-posta adresini ve şifresini paylaştığı kişilerle yapacağı bilgi
                    paylaşımı işbu hükmün ihlali teşkil etmeyecektir.{' '}
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.17. Kurallara Uyma: </h6>
                    ALICI; üyelik süreci boyunca “5846 sayılı Fikir ve Sanat Eserleri Kanunu”, “6769 sayılı Sınai Mülkiyet Kanunu”, “6102 sayılı Türk
                    Ticaret Kanunu”, “5237 sayılı Türk Ceza Kanunu” ve yürürlükteki diğer tüm mevzuat hükümlerine uygun davranacağını, gerek mevzuat
                    gerekse SATICI’nın haklarını kullanması neticesinde ortaya çıkabilecek sınırlamalara riayet edeceğini kabul eder. Aksi takdirde
                    SATICI’nın bu yüzden kamu kurumlarına ve/veya üçüncü şahıslara ödemek zorunda kaldığı/kalacağı her türlü tazminat ve/veya
                    idari/adli para cezaları için ALICI’ya aynen rücu hakkı saklıdır.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.18. Koruma ve Kısıtlamalar: </h6>
                    ALICI; Wise&Rise üzerinde oluşturulmuş korumaların ve kısıtlamaların hiçbirini devre dışı bırakmayacağını, herhangi bir şekilde
                    işlevselliklerini yitirmelerini sağlamayacağını taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.19. Hukuka Uygun Erişim: </h6>
                    ALICI; Wise&Rise’a erişmek için robot, örümcek, kazıyıcı ve başkaca otomatik yollar kullanmayacaktır. ALICI; Wise&Rise üzerinden
                    erişilebilen herhangi bir yazılımı ya da diğer ürünleri veya süreçleri kaynak koda dönüştürmemeyi, bunlar üzerinde parçalara
                    ayırma işlemi yapmamayı; Wise&Rise’ın içeriğine herhangi bir kod veya ürün yerleştirmemeyi veya herhangi bir şekilde manipüle
                    etmemeyi; herhangi bir veri oluşturma, veri toplama ya da çıkarma yöntemi kullanmamayı; her türlü yazılım virüsü ya da herhangi
                    başka bir bilgisayar kodu, dosyaları veya programları dâhil olmak üzere, Wise&Rise ile ilişkili herhangi bir bilgisayar yazılımı
                    ya da donanımı veya telekomünikasyon ekipmanının işlevselliğini kesintiye uğratmak, zedelemek veya sınırlandırmak için tasarlanmış
                    herhangi bir materyali yüklememeyi, göndermemeyi, e-posta ile veya başka şekilde göndermemeyi veya iletmemeyi kabul eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.20. Tazmin Yükümlülüğü: </h6>
                    ALICI; Mesafeli Sözleşme ya da herhangi bir mevzuat hükmüne aykırı davrandığı takdirde, SATICI gerekli müdahalelerde bulunma,
                    hizmeti askıya alma, hizmetleri ve erişimi tamamen veya kısmen sınırlama, engelleme hakkına sahiptir. ALICI; bu hallerde,
                    SATICI’nın maddi-manevi, müspet-menfi her türlü doğrudan-dolaylı zararını ayrıca mahkeme kararına gerek kalmaksızın, ilk talep
                    anında derhâl, nakden ve defaten tazmin edeceğini kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.21. Yükümlülüklerin İhlali: </h6>
                    ALICI; Mesafeli Sözleşme’de belirtilen veya yürürlükteki mevzuattan kaynaklı başkaca yükümlülüklerini ihlal etmesi halinde,
                    üyeliğinin derhal sonlandırılabileceğini, SATICI’nın fazlaya ilişkin her türlü talep ve dava hakkı saklı olduğunu, üçüncü kişilere
                    verilen zararlardan SATICI’nın sorumluluğunun bulunmadığını, bunlardan kendisinin sorumlu olduğunun kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.22. Üyelik Devam Koşulları: </h6>
                    Wise&Rise üyeliği; Mesafeli Sözleşme şartlarına uyulduğu ve üyenin hizmet alma iradesi devam ettiği müddetçe; belirlenen süre ve
                    şartlar dâhilinde devam eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.23. Cayma Hakkı: </h6>
                    ALICI; SATICI tarafından kendisine sağlanan bu hizmetlerin Mesafeli Sözleşmeler Yönetmeliği’nin 15. (on beşinci) maddesinin ‘‘ğ’’
                    bendinde yer alan ‘‘Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi mallara
                    ilişkin sözleşmeler’’ kapsamında olduğunu, bu sebeple mevzuat gereği Wise&Rise’a giriş yaparak dijital içeriklere eriştiği andan
                    itibaren cayma hakkının bulunamayacağını peşinen kabul eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.24. Hizmet ve Planın Yenilenmesi: </h6>
                    <br /> - ALICI; yenilenmenin aşağıda yazılı hükümler çerçevesinde olduğunu kabul eder. <br />
                    <ul className="pl-4 mt-2">
                      <li>
                        {' '}
                        ALICI; iptal etmediği müddetçe, satın alım tarihinden tam 1 (bir) yıl sonrasına tekabül eden tarihte, SATICI tarafından
                        ALICI’ya en geç 30 (otuz) gün öncesinden e-posta yoluyla bildiren fiyat üzerinden, ‘‘ödeme ve kart bilgileri / planlarım’’
                        başlıklı sayfada ALICI tarafından bilgisi verilen kredi/banka kartından ödeme kuruluşu Iyzico aracılığıyla tahsil edilmek
                        suretiyle kendiliğinden yenilenir. SATICI’nın ödeme denemelerine ilişkin hakları saklıdır.
                      </li>
                      <li>ALICI; aboneliğinin yenilenmesini talep etmiyorsa, planlarım bölümünden abonelik planını iptal etmekle yükümlüdür.</li>
                      <li>
                        ALICI; aboneliğini iptal ettiğinde, iptal ettiği takvim günündeki ayın sonuna kadar hizmet konusu içeriklere erişim
                        sağlayabilir.
                      </li>
                      <li>
                        ALICI; aboneliğini iptal ettiğinde, iptal ettiği takvim günündeki ayın sonuna kadar hizmet konusu içeriklere erişim
                        sağlayabilir.
                      </li>
                      <li>
                        {' '}
                        Mesafeli Sözleşme’nin 4 (dört) numaralı maddesinde de belirtilen ödeme denemesi çabalarının olumlu yönde sonuç vermemesi
                        durumunda, SATICI, hizmeti derhal sona erdirebilir.
                      </li>
                    </ul>
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.25. Ödeme Denemeleri: </h6>
                    ALICI;satın alınması durumunda; planlarım sayfasından veya üzerinden her daim bilgisine erişebileceği yenileme döneminden önce
                    aboneliğini iptal etmediğinde, bilgisini vermiş olduğu kredi / banka kartından otomatik olarak tahsilat gerçekleştirilebileceğini
                    kabul, beyan ve taahhüt eder. İlgili tahsilatın kart bakiyesinin yetersiz olması nedeniyle gerçekleştirilemediği durumlarda;
                    SATICI, ödeme kuruluşu aracılığıyla bir süre daha ilgili tahsilatı gerçekleştirmeyi deneyebilir. ALICI; bu süre zarfında
                    hizmetlere erişebilir, ancak yenileme döneminin bittiği tarihten itibaren dört gün boyunca ilgili tahsilat tamamlanamaz ise
                    SATICI, hizmetleri ALICI’nın erişimine kapatabilir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.26. Bilgi Güvenliği: </h6>
                    ALICI tarafından Wise&Rise’a girilen bilgilerin ve işlemlerin güvenliği için gerekli önlemler; SATICI tarafındaki sistem
                    altyapısında, bilgi ve işlemin mahiyetine göre teknik imkânları ölçüsünde alınmıştır. ALICI; kendisi tarafından ve kendisine ait
                    cihaz üzerinden bilgilerini girdiğinden; bu bilgilerin korunması ve bu bilgilere ilgisiz kişilerce erişilememesi için, virüs ve
                    benzeri zararları uygulamalara ilişkin olanlar dâhil, gerekli tedbirlerin alınması sorumluluğunun yalnızca kendisine ait olduğunu
                    kabul, beyan ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.27. Kişisel Veriler: </h6>
                    Detayları Aydınlatma Metni’nde izah edildiği üzere; ALICI’nın abonelik hizmetini satın aldığı esnada paylaştığı tüm kişisel
                    veriler ilgili yasalara, Kişisel Verileri Koruma Kurulu kararlarına ve diğer tüm düzenlemelere uygun şekilde temin edilir,
                    işlenir, saklanır ve aktarılır.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.28. Çerez Politikası: </h6>
                    Wise&Rise’da yer alan çerez (tanımlama bilgisi) uygulamalarını kabul etmeniz halinde, çerezler (tanımlama bilgileri){' '}
                    <Link className="text-danger" to="/cerez-politikasi">
                      Çerez Politikası
                    </Link>{' '}
                    uyarınca ziyaretçi ve/veya üyenin cihazına yerleştirilir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.29. Bağlayıcılık: </h6>
                    ALICI; üyeliğe konu dijital içerik hizmetlerin temel nitelikleri ve diğer tüm hususlarda bilgi sahibi olduğunu ve Kullanım
                    Şartları Bildirimi ile Mesafeli Sözleşme’nin ve bunlarda yer alan üyelik koşullarının bağlayıcı olduğunu kabul ve beyan eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">5.30. Belge Önceliği: </h6>
                    ALICI; Kullanım Koşulları ile Mesafe Sözleşme’nin birlikte geçerli olacağını; her iki belge arasında çelişki olması halinde,
                    Mesafeli Sözleşme’nin öncelikli olarak uygulanacağını kabul eder.
                  </p>

                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="my-3"> MADDE 6 – SATICI’NIN HAK VE SORUMLULUKLARI</h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">6.1. Kişiye Özel Üyelik Hizmeti: </h6>
                    SATICI; Wise&Rise üzerinden ALICI’ya internete bağlı telefon, bilgisayar, televizyon gibi cihazlar aracılığıyla internet
                    teknolojisi üzerinden, çeşitli konularda uzmanlığı / yetkinliği bulunan eğitmenlerin icra ettiği / yer aldığı içeriklere
                    erişilebilmesini sağlayan bir hizmet sunacağını beyan eder. Bu üyelik hizmeti, satın alınan plana göre sınırlı sayıdaki bazı
                    kişilerin kullanımına sunma imkânı tanıyabilmekle birlikte, kural olarak kişiye özel niteliktedir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">6.2. Değişen Hizmet Şartları ve Bilgilendirme: </h6>
                    SATICI; en iyi kullanıcı deneyimini sunabilmek amacıyla teknolojide, hizmetlerde ya da ücret politikasında her türlü değişikliği
                    gerçekleştirme hakkını saklı tutar. Bu süreçte SATICI’nın ALICI’ya karşı yasal sınırlar dâhilinde herhangi bir yükümlülüğü
                    bulunmaz. Bahsedilen değişiklikler; sayılanlarla sınırlı olmamak üzere teknik sorunlar, bakım dönemleri, testler, resmi otorite
                    kararları, üçüncü taraf saldırıları sebebiyle çeşitli kesintiler; aylık abonelik bedelindeki değişiklikler; içeriklerin
                    güncellenmesi, içerik kaldırma ya da eklemeyi kapsar. SATICI, böyle bir durumda ALICI’yı bilgilendirir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">6.3. Yıllık Plan Ücretinde Değişiklik Hakkı: </h6>
                    SATICI; ALICI’nın Yıllık Plan’ının / Nitelikli Yıllık Planı’nın bir sonraki yenileme döneminde geçerli olmak üzere Yıllık Plan /
                    Nitelikli Yıllık Plan ücretlerinde değişiklik yapabilir. SATICI, yapacağı değişikliği en geç 30 (otuz) gün öncesinde ALICI’ya
                    internet sitesi veya e-posta aracılığıyla bildirir. ALICI’nın değişiklik yapılmış ücret üzerinden gerçekleştirdiği abonelik planı
                    yenilemesine itiraz hakkı mevcut değildir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">6.4. Sorun Halinde Satış Durumu: </h6>
                    SATICI; Wise&Rise’da sunulan bilgi ve/veya hizmetlerde eksiklik, iletişim sorunları, teknik problemler, altyapı ve/veya internet
                    arızaları, elektrik kesintisi veya başkaca problemler olması durumunda gerek satış başlamadan önce gerek satış esnasında veya
                    satış yapılmış olsa dahi ALICI’ya herhangi bir bildirimde bulunmaya gerek olmaksızın ve/veya sebep göstermeksizin satışı
                    durdurmaya ve/veya sona erdirmeye ve/veya iptal etmeye yetkilidir. ALICI bu nedenlerle SATICI’dan hiçbir nam altında hak veya
                    alacak talep edemez.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="my-3"> MADDE 7 - SORUMLULUĞUN SINIRLANDIRILMASI</h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">7.1. Bilimsel ve Yorumsal Doğruluk: </h6>
                    ALICI; Wise&Rise üzerinden sağlanacak içeriklere, içeriklerin çeşitliliğine, sayısına ve içeriklerde yer alan tüm bilgi, öneri ve
                    tavsiyelerin bilimsel ve/veya yorumsal doğruluğuna ilişkin olarak SATICI tarafından herhangi bir surette taahhütte bulunulmadığını
                    kabul ve beyan eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">7.2. Zarar Sorumluluğu: </h6>
                    ALICI; SATICI’nın Wise&Rise hizmetlerinin hatasız olacağına veya sürekli sağlanacağına veya hizmetlerin virüs ve diğer zararlı
                    unsurlardan arınmış olacağına dair garanti vermediğini; ağır kusur veya kast halleri hariç olmak üzere, ALICI’nın doğrudan ve/veya
                    dolaylı zararlarından SATICI’nın sorumlu olmadığını bildiğini kabul ve taahhüt eder.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">7.3. Bağlantılı Link ve Reklamlar: </h6>
                    ALICI; SATICI’nın, Wise&Rise’da yer alan ve üçüncü taraflara ait bağlantılı linklerde yer alan reklamların konusunu teşkil
                    edebilecek ürün ve/veya hizmetlere ilişkin yahut üçüncü taraf uygulama ve cihazları ile erişim sağlanacak hizmetlere yönelik
                    hiçbir sorumluluğu bulunmadığını; SATICI’nın bu hususlarla ilgili herhangi bir garanti sunmadığını kabul eder.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> MADDE 8 – SÖZLEŞMENİN FESHİ ve FESHİN SONUÇLARI</h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">8.1. SATICI’nın Fesih Hakkı: </h6>
                    Satın alınan hizmetin Aylık Plan, Nitelikli Aylık Plan, Yıllık Plan veya Nitelikli Yıllık Plan dâhilinde olup olmadığı fark
                    etmeksizin; ALICI’nın yükümlülüklerinden herhangi birine kısmen ve/veya tamamen aykırı davranması durumunda, SATICI; Mesafeli
                    Sözleşme’yi feshedebilir ve ALICI’nın Wise&Rise’a erişim hakkını fesih tarihi itibariyle sonlandırabilir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">8.2. ALICI’nın Aylık Planlarını Fesih Prosedürü: </h6>
                    ALICI; Aylık Plan’ı ve Nitelikli Aylık Plan’ı dilediği zaman iptal ederek Mesafeli Sözleşme’yi feshedebilir. Bu durumda ALICI
                    ilgili aydaki bakiye süre boyunca Wise&Rise’a erişim sağlayabilir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">8.3. ALICI’nın Yıllık Planlarını Fesih Prosedürü: </h6>
                    ALICI; Yıllık Plan’ı ve Nitelikli Yıllık Plan’ı iptal ederek Mesafeli Sözleşme’yi feshedebilir. Şu kadar ki; Yıllık Plan’ın ve
                    Nitelikli Yıllık Plan’ın ALICI tarafından süresinden önce iptal edilmesi durumunda; SATICI, ALICI’nın aboneliğine son verdiği
                    tarihe kadar ALICI’nın Yıllık Plan / Nitelikli Yıllık Plan kapsamındaki taahhüdüne istinaden yapmış olduğu tüm indirimleri
                    ALICI’dan talep edebilir. ALICI’nın Yıllık Plan veya Nitelikli Yıllık Plan kapsamında SATICI’ya ödemeyi taahhüt ettiği bedelin
                    henüz tahakkuk etmemiş kısmının toplamının bu bedelden düşük olması halinde, ALICI’nın lehine olan tutar ALICI’dan tahsil edilir.
                    ALICI; Yıllık Plan / Nitelikli Yıllık Plan kapsamındaki taahhüdüne istinaden yapmış olduğu indirimlerin (veya SATICI’ya ödemeyi
                    taahhüt ettiği bedelin henüz tahakkuk etmemiş kısmının toplamının bu bedelden düşük olması halinde, ALICI’nın lehine olan tutarın)
                    SATICI tarafından, ‘‘ödeme ve kart bilgileri / planlarım’’ başlıklı sayfada ALICI tarafından bilgisi verilen kredi / banka
                    kartından ödeme kuruluşu Iyzico aracılığıyla tahsil edilmesine muvafakatinin bulunduğunu kabul ve beyan eder. Yıllık Plan’ın veya
                    Nitelikli Yıllık Plan’ın iptal edilmesi durumunda ALICI, Yıllık Plan’ı veya Nitelikli Yıllık Plan’ı iptal ettiği takvim günündeki
                    ayın sonuna kadar Wise&Rise’a erişim sağlayabilir.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> MADDE 9 – MUHTELİF HÜKÜMLER</h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.1. Ön Bilgilendirmeye İlişkin Teyit: </h6>
                    ALICI; Mesafeli Sözleşme’yi onayladığında Mesafeli Sözleşmeler Yönetmeliğinin 6. (altıncı) maddesinde belirtilen usullere uygun
                    olarak SATICI tarafından kendisine ön bilgilendirmede bulunulduğunu kabul etmiş sayılacağını bildiğini beyan ve taahhüt eder.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.2. Sürdürülebilirlik: </h6>
                    Mesafeli Sözleşme’nin herhangi bir veya birden fazla hükmünün geçersiz, yasalara aykırı veya uygulanamaz ilan edilmesi durumunda,
                    kalan hükümlerin geçerliliği bu durumdan etkilenmez.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.3. Tebligatlar: </h6>
                    SATICI ile ALICI arasındaki her türlü bildirim ve iletişim, mevzuatta sayılan zorunlu haller dışında, yalnızca elektronik ortamda
                    ve elektronik formatta olmak üzere Mesafeli Sözleşme’de yazılı e-posta adresleri aracılığıyla yapılacaktır.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.4. Mücbir Sebep: </h6>
                    İnternette yaşanan kesintiler, hız düşmesi ve benzeri sebeplerle hizmette yaşanabilecek kesinti, görüntü kalitesi ve güvenlik
                    problemlerinden ve/veya ülkede genel bir karışıklığın ortaya çıkması savaş, terör, grev, genel elektrik kesintisi, deprem, su
                    baskını, pandemi, olağanüstü hal, siber saldırı, cihazlara virüs bulaşmış olması veya benzeri bir mücbir sebeplerden Veranova
                    Medya sorumlu değildir.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.5. Devir ve Temlik: </h6>
                    SATICI, üyelik ilişkisine dair haklarını ve yükümlülüklerini herhangi bir zamanda bir başkasına devir ve temlik edebilir.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.6. Uyuşmazlıkların Çözümü: </h6>
                    Mesafeli Sözleşme’den doğabilecek tüm uyuşmazlıklarda Türk Hukuku uygulanır. Ticaret Bakanlığınca yasa gereği her yıl
                    belirlenen/ilan edilen parasal sınırlar dâhilinde İl ve İlçe Tüketici Hakem Heyetleri, bu sınırları aşan durumlarda Tüketici
                    Mahkemeleri yetkili ve görevlidir. ALICI, bu çerçevede, kendisinin veya dilerse SATICI’nın yerleşim yerindeki Hakem Heyetleri ve
                    Tüketici Mahkemelerine başvurabilir.{' '}
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.7. Delil Sözleşmesi: </h6>
                    Mesafeli Sözleşme’den doğabilecek tüm uyuşmazlıklarda SATICI’ya ait ticari defter kayıtları, kalıcı veri saklayıcıları, veri
                    tabanları, sunucu ve bilgisayar kayıtları ile tüm elektronik kayıtlar kesin delil teşkil eder. ALICI, Mesafeli Sözleşme’yi
                    onaylayarak işbu hükmün Hukuk Muhakemeleri Kanunu’nun 193. maddesi uyarınca delil sözleşmesi olduğunu kabul eder.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3"> MADDE 10 – İMZA ve YÜRÜRLÜK, ÖRNEK GÖNDERİMİ</h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">10.1. İmza ve Yürürlük: </h6>
                    Toplam 10 (on) maddeden ibaret olan Mesafeli Sözleşme; ALICI tarafından elektronik ortamda onaylanmak suretiyle
                    kurulmuş/imzalanmış ve yürürlüğe girmiştir.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">10.2. Örnek Gönderimi: </h6>
                    Sözleşme’nin bir örneği, onay sonrasında SATICI tarafından ALICI’ya elektronik posta aracılığıyla gönderilir.
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th className="w-50 text-center">SATICI/SAĞLAYICI </th>
                        <th className="text-center">ALICI</th>
                      </tr>
                    </thead>
                    <tbody className="text-start">
                      <tr>
                        <td>
                          <b>Ticari Unvan : </b>
                          Veranova Medya ve Dijital Yayın Hizmetleri A.Ş.
                        </td>
                        <td>
                          {' '}
                          <b>İsim Soyisim : </b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Vergi No : </b>
                          9241000351 (Beşiktaş V.D.) <br />
                          <b>MERSİS No : </b>
                          0924100035100001
                        </td>
                        <td>
                          {' '}
                          <b>TCKN : </b>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Adres : </b>
                          Akat Mah. Cebeci Cad. No:65 Beşiktaş/İstanbul
                        </td>
                        <td>
                          {' '}
                          <b>Adres : </b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>E-Posta : </b>
                          info@wisenrise.com
                        </td>
                        <td>
                          {' '}
                          <b>E-Posta : </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
