import { useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ContractApproval({ handleClose }) {
  const [accept, setAccept] = useState(true);
  return (
    <>
      {/* <div className={`d-flex justify-content-between align-items-start `}>
        <div className="d-flex flex-wrap flex-sm-nowrap  justify-content-center justify-content-sm-start " style={{ width: '90%' }}></div>
        <div className="" style={{ position: 'absolute', top: '0', right: '5px' }}>
          <img onClick={handleClose} width="30" src={closeIcon} alt="" />
        </div>
      </div> */}
      <Col sm="12" className="mt-4 mb-4">
        <div className="custom-control custom-checkbox d-inline-block">
          <input type="checkbox" className="custom-control-input" id="comelectronik" />
          <label className="custom-control-label" htmlFor="comelectronik">
            <Link to="/ticari-elektronik-ileti-onay-metni" target="_blank" className="text-primary">
              Ticari Elektronik İleti Onay Metni
            </Link>{' '}
            'ni okudum, onaylıyorum.{' '}
          </label>
        </div>
      </Col>
      <Col sm="12" className="mb-4">
        <div className="custom-control custom-checkbox d-inline-block">
          <input type="checkbox" className="custom-control-input" id="privacyPolicy" onChange={() => setAccept(!accept)} />
          <label className="custom-control-label" htmlFor="privacyPolicy">
            <Link to="/gizlilik-politikasi" target="_blank" className="text-primary">
              Aydınlatma Metni
            </Link>{' '}
            ’ni okudum, kişisel verilerimin işlenmesi ve yurt dışına aktarılmasına rızam vardır.
          </label>
        </div>
      </Col>
      <Col sm="12" className="mb-4">
        <div className="custom-control custom-checkbox d-inline-block">
          * Devam Et butonuna basarak 18 yaşından büyük olduğunuzu ve{' '}
          <Link to="/kullanim-kosullari" target="_blank" className="text-primary">
            {' '}
            Kullanım Koşulları
          </Link>
          ’nı kabul etmiş olursunuz.
        </div>
      </Col>
      <Button type="submit" className="btn btn-hover btn-primary1 my-2 w-100" disabled={accept} onClick={handleClose}>
        Devam Et
      </Button>
    </>
  );
}
