import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from '../../../contexts/RequestContext';
import { createCategory, updateCategory, updateCategoryBanner } from '../../../Services/Category';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';

const teacherSchema = Yup.object().shape({
  name: Yup.string().min(3, 'En az 3 karakter olmalı!').required(),
  order: Yup.number().required(),
  description: Yup.string().required(),
  is_active: Yup.string().required(),
});

export default function AddCategory({ category, handleClose, update, refetch }) {
  const { Refresh } = useApi();
  const [isLoading, setIsloading] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState('');
  const [fileDataURL, setFileDataURL] = useState(null);
  const initialValues = {
    ...category,
    name: category?.name,
    order: category?.order,
    parent_id: category?.parent_id,
    description: category?.description,
    is_active: category?.is_active,
  };
  let formData = new FormData();
  const updateBanner = () => {
    formData.append('banner', file);
    updateCategoryBanner({ slug: category.id, body: formData }).then((res) => {});
  };
  const formik = useFormik({
    initialValues,
    validationSchema: teacherSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsloading(true);
      if (update) {
        const body = {
          name: values.name,
          order: values?.order,
          parent_id: null,
          description: values.description,
          is_active: values?.is_active,
        };
        if (file) updateBanner();
        updateCategory({ slug: category.id, body })
          .then((res) => {
            notify('Başarılı güncellendi!');
            Refresh();
            refetch();
            handleClose();
          })
          .catch((err) => {
            notifyError(err.response.data.message);
          });
      } else {
        try {
          formData.append('name', values.name);
          formData.append('order', values.order);
          formData.append('description', values.description);
          formData.append('is_active', values.is_active);
          formData.append('banner', file);
          await createCategory(formData)
            .then((res) => {
              notify('Başarılı  eklendi!');
              Refresh();
              refetch();
              handleClose();
            })
            .catch((err) => {
              console.log(err);
              notifyError(err.response.data.message);
            });
        } catch (error) {}
      }
    },
  });

  useEffect(() => {
    if (update) {
      setFileDataURL({ image: category?.banner?.path });
    }
  }, []);

  return (
    <>
      <section className="">
        <Row className="row align-items-center m-profile justify-content-center h-100" style={{ padding: '20px' }}>
          <Col lg="12">
            <div className="p-5 text-left">
              <Row>
                <Col className="device-margin">
                  <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                    <Row>
                      <Col sm="6">
                        <Form.Group className="">
                          <Row className="ml-1 mb-2">
                            <Form.Label htmlFor="name" style={{ color: 'white' }}>
                              Kategori İsmi <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>
                          {/* <Row> */}
                          <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            name="name"
                            className="form-control mb-0"
                            id="exampleInputName"
                            placeholder=" Kategori İsmi gir"
                            autoComplete="off"
                            required
                            //defaultValue={teach?.name}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.name}
                              </span>
                            </div>
                          )}
                          {/* </Row> */}
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group className="">
                          <Row className="ml-1 mb-2">
                            <Form.Label htmlFor="name" style={{ color: 'white' }}>
                              Kategori Sırası <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>
                          {/* <Row> */}
                          <Form.Control
                            type="text"
                            {...formik.getFieldProps('order')}
                            name="order"
                            className="form-control mb-0"
                            id="exampleInputOrder"
                            placeholder="Sırasını gir"
                            autoComplete="off"
                            required
                            //defaultValue={teach?.name}
                          />
                          {formik.touched.order && formik.errors.order && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.order}
                              </span>
                            </div>
                          )}
                          {/* </Row> */}
                        </Form.Group>
                      </Col>

                      <Col sm="12" className="">
                        <Form.Group>
                          <Row className="ml-1 mb-2">
                            <Form.Label htmlFor="description" style={{ color: 'white' }}>
                              Açıklama <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>

                          <textarea
                            type="text"
                            {...formik.getFieldProps('description')}
                            name="description"
                            className="form-control mb-0"
                            id="exampleInputEmail1"
                            placeholder="Enter description"
                            autoComplete="off"
                            required
                            style={{ lineHeight: '25px' }}
                          />

                          {formik.touched.description && formik.errors.description && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.description}
                              </span>
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm="6" className="multi mb-3">
                        <Form.Label htmlFor="description" style={{ color: 'white' }}>
                          Yayınla <span className="text-primary">*</span>
                        </Form.Label>
                        <select
                          className="mt-2"
                          data-control="select"
                          data-hide-search="true"
                          {...formik.getFieldProps('is_active')}
                          name="is_active"
                          defaultValue={'null'}
                        >
                          <option style={{ background: '#000', color: '#fff' }} value={'null'}>
                            Seç
                          </option>
                          <option style={{ background: '#000', color: '#fff' }} value={'0'}>
                            Hayır
                          </option>
                          <option style={{ background: '#000', color: '#fff' }} value={'1'}>
                            Evet
                          </option>
                        </select>
                        {formik.touched.is_active && formik.errors.is_active && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span className="text-primary" role="alert">
                                {formik.errors.is_active}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="multi">
                        <div className="">
                          <Dropzone
                            accept={{
                              'image/*': ['.png', '.jpg', '.jpeg'],
                            }}
                            multiple={false}
                            maxFiles={1}
                            className="d-flex justify-content-start align-items-center"
                            onDrop={async (acceptedFiles, fileRejections) => {
                              setFile(acceptedFiles[0]);

                              setFileDataURL({
                                image: URL.createObjectURL(acceptedFiles[0]),
                              });
                              //handleChange(acceptedFiles)
                              fileRejections.forEach((file) => {
                                file.errors.forEach((err) => {
                                  if (err.code === 'file-too-large') {
                                    setErrors(`Hata: ${err.message}`);
                                  }

                                  if (err.code === 'file-invalid-type') {
                                    setErrors(`Hata: ${err.message}`);
                                  } else {
                                    setErrors(`Hata: ${err.message}`);
                                  }
                                });
                              });
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                  <input {...getInputProps()} accept="image/*" className="form-control mb-0 form-control" placeholder="Select Img" />

                                  <div>
                                    {' '}
                                    <i className="fa fa-image ml-3"></i> <span> {fileDataURL ? 'Yeni' : ''} Resim Seç</span>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <aside className="d-flex justify-content-center p-3">
                          <div style={{ maxWidth: '300px', maxHeight: 'auto' }}>
                            <img src={fileDataURL?.image} />
                          </div>
                        </aside>
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <Row>
                        <Col sm="12">
                          <div className="d-flex justify-content-end">
                            <div onClick={handleClose} className="btn btn-link">
                              İptal
                            </div>
                            <button
                              disabled={(formik.isSubmitting || !formik.isValid || !formik.touched) && !update}
                              type="submit"
                              className="btn btn-hover"
                            >
                              Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
