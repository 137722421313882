import React, { useEffect, useState } from 'react';
import PageTitle from './components/PageTitle';
import { useQuery } from '@tanstack/react-query';
import SignUp from '../../components/Signup';
import { useParams } from 'react-router-dom';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import AboutLesson from './components/AboutLesson';
import LesonsList from './components/LesonsList';
import DownloadGuid from './components/DownloadGuid';
import OtherClasses from './components/OtherClasses';
import ClassesSlider from '../../components/ClassesSlider';
import { useAuth } from '../../contexts/AuthContext';
import { getClassroomsDetails } from '../../Services/Classroom';
import { useApi } from '../../contexts/RequestContext';
import ClassroomVideo from '../Admin/Video/AddVideo';
import LoadingPage from '../Loading/loadingPage';
import { isMobile } from '../../utils/isMobil';
import { fbEvent, trackEvent, trackTikTokEvent } from '../../libs/pixels';

export default function LessonsDetail() {
  const { slug } = useParams();
  const { inLogin, isAdmin } = useAuth();
  const { teacherSet } = useApi();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { isLoading, error, data, refetch } = useQuery(['lessonsDetail1', slug], getClassroomsDetails);

  useEffect(() => {
    if (data) {
      teacherSet(data?.data?.teacher);
      fbEvent('ViewContent', {
        content_name: data?.data?.name,
        content_type: 'education',
        content_ids: [data?.data?.id],
      });
      trackEvent('view_item', {
        items: [{ id: data?.data?.id, item_category: 'education' }],
      });
      trackTikTokEvent('ViewContent', {});
    }
    return () => {};
  }, [data]);
  if (isLoading && !isMobile()) return <LoadingPage />;
  if (isLoading && isMobile()) return 'Yükleniyor...';

  if (error) return 'An error has occurred: ' + error.message;
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: data?.data?.name,
    description: data?.data?.description,
    thumbnailUrl: [data?.data.thumbnail?.path],
    uploadDate: data.data?.created_at,
    duration: 'PT1M54S',
    contentUrl: 'https://wisenrise.s3.eu-central-1.amazonaws.com/01_MEHMET_TURGUT/hls/MT_B000/0+Giri%C5%9F.m3u8',
    embedUrl: window.location.href,
    regionsAllowed: 'TR',
  };
  const ldJson2 = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Wise&Rise',
        item: 'https://www.wisenrise.com',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: data?.data?.category?.name,
        item: 'https://' + window.location.host + '/' + data?.data?.category?.slug,
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: data?.data?.name,
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>
          {data?.data?.name} | {data?.data?.teacher?.name} - Wise & Rise
        </title>
        <meta
          name="description"
          content={`${data?.data?.name} konusunda ${data?.data?.teacher?.name} tarafından hazırlanan eğitimi izle, Wise&Rise'da ilgi alanına yönelik eğitimleri keşfetmeye başla!`}
        />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        <script type="application/ld+json">{JSON.stringify(ldJson2)}</script>
      </Helmet>
      <PageTitle item={data?.data} />
      {inLogin ? '' : <SignUp text={'Şimdi İlerle'} />}

      <div className="main-content mt-5">
        {/* start slider */}
        <section id="iq-favorites">
          <Container className="mb-4 mb-md-5">
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="main-title channel-logo w-100 text-left">Eğitim Hakkında</h4>
                  <div className="d-flex align-items-center justify-content-between"></div>
                </div>
                <div id="favorites-contens">
                  <AboutLesson item={data?.data} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container className="mt-0 mt-md-5">
            {/* {isAdmin && (
              <Row>
                <Col sm="12" className="d-flex justify-content-end w-100">
                  <div onClick={handleShow} className="btn btn-hover">
                    Yeni Eğitim
                  </div>
                </Col>{' '}
              </Row>
            )} */}

            <Row className="mt-0 mt-md-5">
              <Col sm="12" className="overflow-hidden">
                <div id="favorites-contens">
                  {data?.data?.videos?.map((item, index) => {
                    return (
                      <LesonsList
                        key={index}
                        item={item}
                        items={data?.data}
                        index={index}
                        refetch={refetch}
                        classroom_id={data?.data?.id}
                        classroom_slug={data?.data?.slug}
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* {inLogin && (
          <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
            <div className="mb-5 mt-5">
              <Container>
                <DownloadGuid
                  title="Ders Notlarını İndir"
                  desc="Alanında uzman isimlerden istediğin zaman öğrenebilirsin. Yıl boyunca sınırsın erişim. "
                  doc={data?.data?.document}
                />
              </Container>
            </div>
          </section>
        )} */}

        <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
          <div className="mb-5 mt-5">
            <Container>
              <OtherClasses />
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="main-title  w-50 text-left"></h4>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                    <div id="prevSlider" className="cursor-pointer">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div id="nextSlider" className="cursor-pointer">
                      <i className="fa fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <ClassesSlider prev="prevSlider" next="nextSlider" query={`?category_id=${data.data.category.id}`} visible={() => {}} />
            </Container>
          </div>
        </section>

        {!inLogin && (
          <section id="home" className="iq-main-slider d-none p-0 iq-rtl-direction">
            <div className="mb-5 mt-5">
              <Container>
                <DownloadGuid title="İlgini Çekebilecek Eğitimler" desc="E-posta adresini gir, gelişimin için dijital çağın yeni okuluna katıl. " />
              </Container>
            </div>
          </section>
        )}
      </div>
      <Modal show={show} onHide={handleClose} centered style={{ background: '#000' }} size="lg">
        <Modal.Body style={{ background: '#000' }}>
          <ClassroomVideo
            video={null}
            update={false}
            handleClose={handleClose}
            classroom_id={data?.data?.id}
            refetch={refetch}
            classroom_slug={data?.data?.slug}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
