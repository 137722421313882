import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Container, Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { getClassrooms } from '../../../Services/Classroom';
import { isMobile } from '../../../utils/isMobil';
import style from './style.module.css';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function Sliders(props) {
  const [skeletoon, setSkeleton] = useState([1, 2, 3, 4, 5]);
  const { isLoading, data } = useQuery(['Discover', props?.q ? props?.q : '/sorted/coming_soon'], () =>
    getClassrooms(props?.q ? props?.q : '/sorted/coming_soon')
  );

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Window boyutu değiştiğinde handleResize fonksiyonunu çalıştır
    window.addEventListener('resize', handleResize);
    // Cleanup, event listener'ı component kaldırıldığında temizler
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const preventDefault = (e) => {
    e.preventDefault();
  };
  const getTitle = (text) => {
    if (width < 720) return `${text?.slice(0, 15)}  ${text?.length > 15 ? '...' : ''}`;
    else return text;
  };

  const formatName = (name) => {
    if (!name) return '';
    return name.replace(/\bVE\b/g, 've');
  };

  return (
    <Container>
      <Row>
        <Col sm="12" className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center justify-content-between fs-small w-100">
              <div style={{ width: '50px' }} className=" w-100 d-flex align-items-center justify-content-end mr-3">
                <div id={'prevV'} className="mx-2">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div id={'nextV'} className="">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div id="favorites-contens">
            <Swiper
              navigation={{
                prevEl: `#prevV`,
                nextEl: `#nextV`,
              }}
              breakpoints={{
                320: { slidesPerView: 2 },
                400: { slidesPerView: 2 },
                430: { slidesPerView: 2 },
                600: { slidesPerView: 2 },
                991: { slidesPerView: 2 },
                1400: { slidesPerView: 2 },
              }}
              loop={true}
              slidesPerView={2}
              spaceBetween={20}
              as="ul"
              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
            >
              {isLoading &&
                !isMobile() &&
                skeletoon.map((index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ContentLoader
                        speed={1}
                        width={280}
                        height={500}
                        // viewBox="0 0 400 460"
                        backgroundColor="#3b3b3b"
                        foregroundColor="#292929"
                        {...props}
                        className="mr-3"
                        onContextMenu={preventDefault}
                        onClick={preventDefault}
                      >
                        <rect x="0" y="0" rx="2" ry="2" width="280" height="500" />
                      </ContentLoader>
                    </SwiperSlide>
                  );
                })}
              {data?.data?.map((item, index) => {
                return (
                  <SwiperSlide key={index} as="li" onContextMenu={preventDefault} onClick={preventDefault}>
                    <div
                      className="ws-card"
                      style={{
                        background: `url(${item?.thumbnail?.path})`,
                      }}
                    >
                      <div className={`text-white ${style.comingSoonCard}`}>Yakında Gelecek</div>
                      <div className="ws-card-img-bg"></div>
                      <div className="d-flex flex-column justify-content-between align-items-center w-100 ws-coming-soon-title">
                        <div className="text-center d-flex align-items-center justify-content-end flex-column">
                          <div className="ws-card-title">
                            {item?.teacher?.logo ? (
                              <img src={item?.teacher?.logo?.path} alt={`${item?.teacher?.name} logosu`} style={{ width: '70%' }} />
                            ) : (
                              <h3>{formatName(item?.teacher?.name)}</h3>
                            )}
                          </div>
                          <div className="mt-auto pt-3 pt-md-0">
                            <div className="ws-card-line"></div>
                            <div className="d-flex justify-content-center text-center w-100 px-3 mb-1">{getTitle(formatName(item?.name))}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
