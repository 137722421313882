import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { deleteNote, UpdateNote } from '../../Services/Note';
import style from './style.module.css';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../contexts/RequestContext';
import { notify, notifyError } from '../AlartNotify/AlertNotify';

const NoteItemModal = ({ item, handleChange, note, classroomData }) => {
  const history = useHistory();
  const { teacherSet } = useApi();
  const [notes, setNotes] = useState(note?.content);
  const [newNote, setNewNote] = useState('');
  const [createNote, setCreateNote] = useState(false);
  const href = classroomData
    ? `../${classroomData?.teacher?.slug}/${classroomData?.slug}/${item?.video?.slug}?dakika=${note?.duration}`
    : `../${item?.teacher?.slug}/${item?.classroom?.slug}/${item?.slug}?dakika=${note?.duration}`;

  const sendNote = async () => {
    const body = {
      content: newNote,
    };

    UpdateNote(note?.id, body)
      .then((res) => {
        handleChange();
        notify('Başarı İle Güncellendi');
        setCreateNote(false);
        setNotes(newNote);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const del = (id) => {
    Swal.fire({
      title: 'Onayla!',
      text: 'Silmek istediğine emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      cancelButtonText: 'İptal',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNote(id)
          .then((res) => {
            handleChange();
            notify('Başarı İle Silindi');
          })
          .catch((err) => {
            notifyError(err.response.data.message);
          });
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <>
      <div className="d-flex align-items-start mb-2">
        <div className="w-100">
          <div className={`w-100 ${style.wsNoteCart}`}>
            <div className={`d-flex justify-content-between w-100 mb-1`}>
              <div
                onClick={() => {
                  teacherSet(classroomData ? classroomData?.teacher : item?.teacher);
                  history.push(href);
                }}
                className={`${style.time} cursor-pointer`}
              >
                <PlayIcon fill="#fff" /> {note?.duration}
              </div>
              <div>
                <PlayIcon
                  onClick={() => {
                    teacherSet(classroomData ? classroomData?.teacher : item?.teacher);
                    history.push(href);
                  }}
                  className="cursor-pointer mr-3"
                  fill="#fff"
                />
                <i
                  onClick={() => {
                    setNewNote(note?.content);
                    setCreateNote(true);
                  }}
                  className="fa fa-pencil mr-2 cursor-pointer"
                ></i>
                <i onClick={() => del(note?.id)} className="fa fa-trash mr-2 ml-2 cursor-pointer"></i>
              </div>
            </div>
            {createNote ? (
              <div className={`${style.addCard} d-flex flex-column mt-3 w-100`}>
                <textarea
                  type="text"
                  name="description"
                  className={`${style.addCard44}`}
                  id="exampleInputEmail1"
                  placeholder="Enter note"
                  autoComplete="off"
                  rows={1}
                  onChange={(e) => {
                    setNewNote(e.target.value);
                  }}
                  defaultValue={newNote}
                  required
                  style={{ lineHeight: '25px', borderRadius: '10px' }}
                />
                <div className="d-flex justify-content-end w-100 mt-3">
                  <div
                    className="btn btn-link"
                    onClick={() => {
                      setCreateNote(false);
                    }}
                  >
                    İptal
                  </div>
                  <button disabled={!newNote} type="submit" className="btn btn-hover  " style={{ borderRadius: '10px' }} onClick={sendNote}>
                    Kaydet
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="text-muted" style={{ fontSize: '13px' }}>
                  <b className="text-muted" style={{ fontSize: '13px' }}>
                    Ders:
                  </b>{' '}
                  {classroomData ? item?.video?.name : item?.name}
                </div>
                <div className={`text-left ${style.createText}`}>{notes}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoteItemModal;
