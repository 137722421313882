import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const LoginBtn = () => {
  const [isBussiness, setIsBusiness] = useState(false);
  const contactUs = () => {
    window.open('https://0u0c51hhbc2.typeform.com/wisenrise', '_blank');
  };
  useEffect(() => {
    if (window.location.pathname == '/business') setIsBusiness(true);
    else setIsBusiness(false);
  }, [window.location.pathname]);
  return (
    <div className=" d-flex align-items-center">
      {' '}
      <Link to="/login" role="button" className="btn  btn-link">
        Giriş
      </Link>
      {!isBussiness && (
        <Link to="/sign-up" role="button" className="btn btn-hover  ">
          Kayıt Ol
        </Link>
      )}
      {isBussiness && (
        <div onClick={contactUs} role="button" className="btn btn-hover  ">
          Bize Ulaşın
        </div>
      )}
    </div>
  );
};
