import { Container, Row, Col } from 'react-bootstrap';
import image1 from '../../assets/icons/features/play.svg';

const FeatureItem = ({ item }) => {
  return (
    <div>
      <Container className="text-left">
        <Row>
          <div>
            <img src={item.img} />
          </div>
        </Row>
        <Row className="my-4">
          <Row>
            <Col sm="12">
              <h5 className="">{item.title}</h5>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div className="mt-3 small">{item.text}</div>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};
export default FeatureItem;
