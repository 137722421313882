import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { getCountries } from '../../../Services/Country';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bgImg from '../../../assets/images/bg/r3.jpg';
import './style.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Reg1 } from '../../../Services/AuthApi';
import { useAuth } from '../../../contexts/AuthContext';
import { trackEvent } from '../../../libs/pixels';

const SignuoSchema = Yup.object().shape({
  email: Yup.string()
    .email('Lütfen geçerli bir e-posta adresi gir.')
    .min(3, 'En az 3 karakter olmalı!')
    .max(50, 'En fazla 50 karakter olmalı!')
    .required('Lütfen geçerli bir e-posta adresi gir.'),
  phone_number: Yup.string().min(12, 'Lütfen  geçerli bir telefon numarası gir.').required('Lütfen geçerli bir telefon numarası gir.'),
  country_code: Yup.number().required(),
});

const initialValues = {
  email: '',
  phone_number: '',
  country_code: '90',
};

const SignUp = (props) => {
  let history = useHistory();
  const { RegStep } = useAuth();
  const [accept, setAccept] = useState(true);
  const [number, setNumber] = useState('');
  const [countries, setCountries] = useState([]);

  const notify = (text) => {
    toast.error(text, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const onChange = (e) => {
    let code = document.getElementById('phone_number').value;

    setNumber(code);
  };

  useEffect(() => {
    trackEvent('conversion_event_begin_checkout_3', {});
    getCountries()
      .then((data) => {
        setCountries(data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        //notify(err)
      });
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema: SignuoSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let body = {
        email: values.email,
        country_code: Number(values.country_code),
        phone_number: values.phone_number.replaceAll(' ', '').substr(0, 10),
      };
      Reg1(body)
        .then((data) => {
          RegStep(data);
          history.push('/set-password');
        })
        .catch((err) => {
          notify(err.response.data.message);
          // Swal.fire({ title: err.response.data.message, icon: 'info',timer:1500,  background:'#000', color:'#fff',})
        });
    },
  });

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="sign-in-page slick-bg pasition-relative" style={{ backgroundImage: `url(${bgImg})` }}>
        <Container className="w-100">
          <Row className="d-flex justify-content-center align-items-center pasition-relative " style={{ marginTop: '0%' }}>
            <Col xs="12" sm="10" md="8" lg="8" className="align-self-center text-left">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h6 className="mb-3 text-primary text-center">Adım 1/4</h6>
                    <h1 className="h2 mb-3 text-center">Hoş Geldin!</h1>
                    <h6 className="mb-3 text-center">Kayıt olmak için bilgileri doldur.</h6>
                    <Form onSubmit={formik.handleSubmit} noValidate onChange={onChange}>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label className="text-white mb-2" style={{ fontSize: '16px', fontWeight: '800' }}>
                              E-posta
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className="form-control mb-0"
                              id="exampleInputEmail2"
                              {...formik.getFieldProps('email')}
                              name="email"
                              placeholder="E-posta Adresini Gir"
                              autoComplete="off"
                              required
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.email}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm="12" className="">
                          <Form.Label
                            className="text-white"
                            style={{
                              fontSize: '16px',
                              fontWeight: '800',
                              marginBottom: '10px',
                            }}
                          >
                            Telefon Numarası <span className="text-primary">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs="4" sm="4" md="3" lg="2" className="mt-1 pr-0">
                          {/* <Select
                              className=" "
                              placeholder="+90"
                              value={data2.filter((obj) =>
                                selectedValue.includes(obj.value)
                              )} // set selected values
                              {...formik.getFieldProps("code")}
                              name="code"
                              options={data2} // set list of the data
                              isClearable
                            /> */}
                          <div className="styleSelect styleSelect-phone">
                            <select
                              className=""
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="+90"
                              {...formik.getFieldProps('country_code')}
                              name="country_code"
                            >
                              {countries?.map((category) => {
                                return (
                                  <option
                                    style={{
                                      background: '#000',
                                      color: '#fff',
                                    }}
                                    key={category.id}
                                    value={category.dial_code}
                                  >
                                    +{category.dial_code}
                                  </option>
                                );
                              })}
                            </select>
                            {formik.touched.country_code && formik.errors.country_code && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span className="text-primary" role="alert">
                                    {formik.errors.country_code}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs="8" sm="8" md="9" lg="10" className="mt-1">
                          <Form.Control
                            type="tel"
                            className="mb-0 placeholder-color"
                            {...formik.getFieldProps('phone_number')}
                            name="phone_number"
                            id="phone_number"
                            placeholder="(552) 555 5555"
                            value={number_format(number)}
                            autoComplete="off"
                            required
                          />

                          {formik.touched.phone_number && formik.errors.phone_number && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span className="text-primary" role="alert">
                                  {formik.errors.phone_number}
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col sm="12" className="mt-4 mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="comelectronik" />
                            <label className="custom-control-label" htmlFor="comelectronik">
                              <Link to="/ticari-elektronik-ileti-onay-metni" target="_blank" className="text-primary">
                                Ticari Elektronik İleti Onay Metni
                              </Link>{' '}
                              'ni okudum, onaylıyorum.{' '}
                            </label>
                          </div>
                        </Col>
                        <Col sm="12" className="mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input type="checkbox" className="custom-control-input" id="privacyPolicy" onChange={() => setAccept(!accept)} />
                            <label className="custom-control-label" htmlFor="privacyPolicy">
                              <Link to="/gizlilik-politikasi" target="_blank" className="text-primary">
                                Aydınlatma Metni
                              </Link>{' '}
                              ’ni okudum, kişisel verilerimin işlenmesi ve yurt dışına aktarılmasına rızam vardır.
                            </label>
                          </div>
                        </Col>
                        <Col sm="12" className="mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            * Devam Et butonuna basarak 18 yaşından büyük olduğunuzu ve{' '}
                            <Link to="/kullanim-kosullari" target="_blank" className="text-primary">
                              {' '}
                              Kullanım Koşulları
                            </Link>
                            ’nı kabul etmiş olursunuz.
                          </div>
                        </Col>
                      </Row>

                      <Button type="submit" className="btn btn-hover btn-primary1 my-2 w-100" disabled={accept}>
                        Devam Et
                      </Button>
                    </Form>
                  </div>
                </div>
                <div className="spinner-grow text-danger" role="status">
                  <span className="sr-only">Yükleniyor...</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
function number_format(value) {
  if (/^(?=.*\d)[\d ]+$/.test(value)) {
    const v = value
      .replace(/\s+/g, '')
      .replace(/[^0-9]/gi, '')
      .substr(0, 10);
    const parts = [];

    for (let i = 0; i < v.length; i += 3) {
      parts.push(v.substr(i, 3));
    }
    if (parts.length == 4) return `${parts[0]} ${parts[1]} ${parts[2]}${parts[3]}`;
    return parts.length > 1 ? parts.join(' ') : value;
  } else {
    return '';
  }
}

export default SignUp;
