import React, { useRef, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';
import VideoHeaderTitle from './VideoHeaderTitle';
import { Player } from 'react-tuby';
import 'react-tuby/css/main.css';
import ReactHlsPlayer from 'react-hls-player';

const VideoModal = ({ handleClose, item }) => {
  const ref = useRef(null);
  const [playVideo, setPlayVideo] = useState(null);

  useEffect(() => {
    if (item?.teaser) {
      setPlayVideo(item?.teaser);
    } else {
      setPlayVideo('https://wisenrise.s3.eu-central-1.amazonaws.com/01_MEHMET_TURGUT/hls/MT_B000/0+Giri%C5%9F.m3u8');
    }
  }, [item]);

  useEffect(() => {
    const video = ref.current;
    if (video && playVideo) {
      const handleLoadedData = () => {
        video.play().catch(error => {
          console.error('Video play failed:', error);
        });
      };

      video.addEventListener('loadeddata', handleLoadedData);

      return () => {
        video.removeEventListener('loadeddata', handleLoadedData);
      };
    }
  }, [playVideo]);

  return (
    <div style={{ height: '100vh' }} className="video-madol-page">
      <Container className="h-75 w-100 py-0 px-1">
        <VideoHeaderTitle handleClose={handleClose} titleHeader={''} item={item} />
        <div className="video-wrapper d-flex justify-content-center align-items-center">
          <Player
            playerRef={ref}
            src={[
              {
                url: playVideo,
              },
            ]}
            keyboardShortcut={false}
            autoplay={true}
          >
            {(ref, props) => <ReactHlsPlayer playerRef={ref} {...props} />}
          </Player>
        </div>
      </Container>
    </div>
  );
};

export default VideoModal;
