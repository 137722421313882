import React from 'react';
import './style.css';
import { Container, Col, Row, Nav, Tab } from 'react-bootstrap';
import fav1 from '../../../assets/images/blog/yatay2_0006_Gaye Amus.jpg';
import fav2 from '../../../assets/images/blog/yatay2_0004_Janset Bilgin.jpg';
import acarBaltas from '../../../assets/images/blog/acar-baltas-cc.jpg';
import yuceZere from '../../../assets/images/blog/yuece-zerey-cc.jpg';

export default function TeacherComponent({ business }) {
  return (
    <div className="ws-card-teachers">
      <div className="ws-card-teachers-bg"></div>
      <Row className="mb-5">
        <Col></Col>
        <Col>
          <img style={{ borderRadius: '10px' }} src={business ? acarBaltas : fav1} />
        </Col>
      </Row>
      <Row>
        <Col sm="5" md="8">
          <img style={{ borderRadius: '10px' }} src={business ? yuceZere : fav2} />
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}
