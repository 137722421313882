import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import bgImg from '../../assets/images/bg/r3.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
//import "./style.css";
import { useAuth } from '../../contexts/AuthContext';
import Answer1 from './components/Answer1';
import style from './style.module.css';
import { getSurvey, putSurvey } from '../../Services/SurveyApi';
import { getProfile } from '../../Services/Profile';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Answer2 from './components/Answer2';
import { useApi } from '../../contexts/RequestContext';
import ContractApproval from './components/ContractApproval';

const notify = (text) => {
  toast.success(text, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export default function Survey() {
  const { profile, Profile, user } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [ques, setQues] = useState(1);
  const [answer, setAnswer] = useState([]);
  const [show, setShow] = useState(!(user?.roles?.filter((item) => item?.name == 'Member').length > 0));
  const handleClose = () => {
    setShow(false);
  };
  let history = useHistory();
  console.log(user);
  useEffect(() => {
    getSurvey(ques)
      .then((data) => {
        setQuestions(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ques]);

  const handleAddItem = (id) => {
    setAnswer((prevAnswer) => {
      const itemIndex = prevAnswer.findIndex((item) => item.id === Number(id));
      if (itemIndex > -1) {
        const newAnswer = [...prevAnswer];
        newAnswer.splice(itemIndex, 1);
        return newAnswer;
      } else {
        const newAnswer = [...prevAnswer, { id: Number(id) }];
        return newAnswer;
      }
    });
  };
  const onSubmit = () => {
    let body = {
      answers: answer,
    };
    putSurvey(profile?.id, ques, body)
      .then((data) => {
        getProfile(profile?.id)
          .then((data) => {
            console.log(data);
            Profile(data.data.data);
            if (data?.data?.data?.is_survey_completed) {
              history.push('/');
              notify('Başarıyla tamamlandı!');
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // if (ques == 2) {
        //   // if (localStorage.getItem('ws-currentPath')) history.push(localStorage.getItem('ws-currentPath'));

        // }
        setQues(2);
        setAnswer([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="sign-in-page slick-bg ws-bg-credit-card" style={{ backgroundImage: `url(${bgImg})`, height: '100%' }}>
        <Container className="mt-5 text-center">
          <Row className="mb-5 text-center">
            <Col sm="12">
              <h3 className="w-xs-75 mx-auto">{questions?.name}</h3>
              <h6 className="mt-4 text-primary text-center">{ques}/2</h6>
            </Col>
          </Row>
          {ques == 1 && (
            <Row className="mt-2 mt-md-5 text-center w-100 w-md-75 m-auto d-flex justify-content-center">
              {questions?.answers?.map((item, index) => {
                return <Answer1 key={index} item={item} handleAddItem={handleAddItem} />;
              })}
            </Row>
          )}
          {ques == 2 && (
            <Row className="mt-5 text-center w-100 w-md-75 m-auto d-flex justify-content-center pb-3">
              {questions?.answers?.map((item, index) => {
                return <Answer2 key={index} item={item} handleAddItem={handleAddItem} />;
              })}
            </Row>
          )}

          <div className={style.wsBtn}>
            <div className="d-flex align-items-center ">
              {answer.length > 0 && (
                <div className="btn btn-hover w-100" onClick={onSubmit}>
                  {ques == 1 ? ' İleri ' : 'Keşfetmeye Başla'}
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#14161A', overflow: 'hidden' }}>
          <ContractApproval handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}
