import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import { gsap } from 'gsap';
import FsLightbox from 'fslightbox-react';
import '../style.css';
import icon from '../../../assets/video/sample-video.mp4';
import { getClassrooms } from '../../../Services/Classroom';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { useAuth } from '../../../contexts/AuthContext';
import AddToSaveBtn from './AddToSaveBtn';
import VideoModal from '../../../components/VideoModal';
import LoadingPage from '../../Loading/loadingPage';
import { isMobile } from '../../../utils/isMobil';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination, Autoplay]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: '',
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '');

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const HomeTitileComponentInLogin = () => {
  const { inLogin } = useAuth();
  // const spinner = document.getElementById("loadingPage");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [classroom, setClassroom] = useState([]);
  const [toggler1, setToggler1] = useState(false);
  const [selectClass, setSelectClass] = useState(null);

  const animationInit = () => {
    if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {
      const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  const { isLoading, error, data } = useQuery(['HomePageTitle1'], () => getClassrooms('?page[size]=3'), {
    refetchOnMount: true,
  });

  const getDesc = (desc) => {
    return isMobile() ? `${desc?.slice(0, 80)}...` : desc;
  };

  useEffect(() => {
    if (data) {
      // spinner.style.display= "none";
      setClassroom(data?.data);
    }

    return () => {};
  }, [data]);
  if (isLoading && !isMobile()) return <LoadingPage />;

  return (
    <>
      <FsLightbox
        toggler={toggler1}
        sources={[<iframe src={icon} title=" " width="80%" height="auto" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />]}
      />
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        pagination={{
          clickable: true,
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={false}
        id="home-slider"
        autoplay={{
          delay: 5000,
        }}
        className="slider m-0 p-0"
      >
        {classroom?.map((item, index) => {
          return (
            <SwiperSlide
              className={`slide slick-bg slick-title-bg ${isMobile() ? '' : 'slick-bg111'} `}
              style={{
                background: `url(${!isMobile() ? item?.cover?.path : item?.teacher?.photo?.path})`,
              }}
              key={index}
            >
              <Container className="position-relative mt-3 pt-3 pt-md-0 h-100">
                <div className="slider-inner h-100 mt-5 pt-5 pt-md-0 mt-md-2">
                  <Row className="align-items-center iq-ltr-direction h-100 mt-0 mt-md-5">
                    <Col md="8">
                      <div className="w-100 d-flex flex-column align-items-center text-center">
                        {item?.teacher?.logo ? (
                          <div className="d-flex justify-content-center w-75">
                            <img
                              style={{ maxWidth: '400px', minWidth: '200px' }}
                              className="big-title"
                              data-iq-gsap="onStart"
                              data-iq-position-x="-200"
                              src={item?.teacher?.logo?.path}
                            />
                          </div>
                        ) : (
                          <h1 className=" slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">
                            {item?.teacher?.name}
                          </h1>
                        )}
                        <div className="wd-card-line"></div>

                        <div
                          className="d-flex mt-0 mt-md-2 text-light h4 justify-content-center "
                          data-iq-gsap="onStart"
                          data-iq-position-x="-200"
                          data-iq-delay="-0.5"
                          style={{ width: '300px', height: '34px' }}
                        >
                          {item?.name}
                        </div>
                        <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
                          <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" className="my-4">
                            {getDesc(item?.description)}
                          </p>
                        </div>
                        <div className="d-flex align-items-center r-mb-23" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                          {inLogin ? (
                            <>
                              <Link to={`/${item?.teacher?.slug}/${item?.slug}`} className="btn btn-hover">
                                İncele
                              </Link>
                              <AddToSaveBtn item={item} />
                            </>
                          ) : (
                            <>
                              <Link to="/show-details" className="btn btn-hover">
                                Şimdi Kayıt Ol
                              </Link>
                              <Link to="/show-details" className="btn btn-link">
                                <div className="price">
                                  <span>35</span>
                                  <span>350</span>
                                </div>
                                TL /
                                <div className="price">
                                  <span> Aylık </span>
                                  <span> Yıllık </span>
                                </div>
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xl="4" lg="12" md="12" className="trailor-video text-center d-none d-md-block">
                      <Link
                        onClick={() => {
                          handleShow();
                          setSelectClass(item);
                        }}
                        to="/"
                        className="video-open playbtn"
                      >
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="80px"
                          height="80px"
                          viewBox="0 0 213.7 213.7"
                          enableBackground="new 0 0 213.7 213.7"
                          xmlSpace="preserve"
                        >
                          <polygon
                            className="triangle"
                            fill="none"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="73.5,62.5 148.5,105.8 73.5,149.1 "
                          />
                          <circle
                            className="circle"
                            fill="none"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            cx="106.8"
                            cy="106.8"
                            r="103.3"
                          />
                        </svg>
                        <span className="w-trailor">Tanıtımı İzle</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Container>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="video-modal-content"
        style={{
          background: '#000',
          border: '0px solid #000',
          margin: '0px',
        }}
      >
        <Modal.Body
          style={{
            background: '#000',
            border: '0px solid #000',
            margin: '0px',
          }}
        >
          <VideoModal handleClose={handleClose} item={selectClass} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default HomeTitileComponentInLogin;
