import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function PrivacyPolicy1() {
  return (
    <div>
      <div className=" " style={{ paddingTop: '80px', paddingBottom: '60px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Gizlilik Politikası</h1>
                  <span>
                    <Link to="/">Anasayfa</Link> /<span className="text-primary">Gizlilik Politikası</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                <div className="mb-1">
                  <h2 className="h4">VERANOVA MEDYA VE DİJİTAL YAYIN HİZMETLERİ A.Ş. KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</h2>
                  <p className="my-3">
                    6698 sayılı Kişisel Verilerin Korunması Kanunu (<b>“KVKK” </b>veya <b> “Kanun”</b>) uyarınca ‘‘Veri Sorumlusu’’ sıfatını haiz
                    Veranova Medya ve Dijital Yayın Hizmetleri A.Ş. (<b>“Veranova Medya’’</b> veya <b>“Şirket”</b>) olarak; sahibi olduğumuz
                    ‘‘Wise&Rise’’ isimli dijital platform üzerinden bizimle paylaştığınız kişisel verilerinizi, aşağıda ayrıntıları ile açıklanan
                    şartlarda toplamakta, işlemekte ve aktarmaktayız.
                  </p>
                </div>
                <div className="mb-3">
                  <p className="mb-2">
                    İşbu metin ile Veranova Medya, Kanun’da ‘‘İlgili Kişi’’ olarak tanımlanan siz veri sahiplerini, kişisel verilerinizin toplanması,
                    işlenmesi, aktarılması süreçleri ve Kanun kapsamındaki haklarınız hususlarında aydınlatmaktadır.
                  </p>
                  <br />
                  <h3 className="h5 mb-2">1. Veri Sorumlusu</h3>
                  <p className="mb-0">
                    Veranova Medya, kişisel verilerinizin işlenmesi süreçlerinde, Kanun’un 3/1-(ı) maddesinde tanımlanan <i>‘‘Veri Sorumlusu’’</i>{' '}
                    olarak hareket etmek suretiyle kişisel verilerinizin işleme amaçlarını ve vasıtalarını belirlemektedir.
                    <br />
                    <br />
                    Veranova Medya, veri sorumlusu olarak, veri kayıt sistemini kurmak, yönetmek ve veri güvenliğine ilişkin idari ve teknik
                    tedbirleri almakla yükümlüdür. Bu kapsamda, verilerinizi Kanun’un 3/1-(ğ) maddesinde tanımlanan <i>‘‘Veri İşleyen’’</i>
                    sıfatını haiz üçüncü kişilere de işletebilecektir.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h3 className="h5 mb-2">2. İşlenen Kişisel Verileriniz ve Kişisel Verilerinizin İşlenme Amaçları </h3>
                  <p className="mb-0">
                    Wise&Rise üzerinden bizimle paylaştığınız bilgileriniz ve Wise&Rise üzerindeki faaliyetleriniz kapsamında, aşağıda belirtilen
                    kişisel verilerinizi aşağıda sayılan amaçlarla Kanun’un 5. ve 6. maddelerinde belirtilen veri işleme şartları dahilinde
                    işlemektedir.
                    <br />
                    <br />
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th className="w-25 text-center">Veri Sahibi</th>
                          <th className="w-25 text-center">Veri Kategorisi</th>
                          <th>Veri Türleri</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th rowspan="3" className="text-center" style={{ verticalAlign: 'middle' }}>
                            Çevrimiçi <span className="text-primary">Ziyaretçi</span>
                          </th>
                          <td>İletişim</td>
                          <td>E-posta, cep telefonu </td>
                        </tr>
                        <tr>
                          <td>İşlem Güvenliği</td>
                          <td>
                            IP Adresi, İnternet Sitesi Giriş, Çıkış ve Gezinme Bilgisi, Log Kayıtları, Hesap Kullanıcı Bilgileri, Cihaz MAC Adresi
                          </td>
                        </tr>
                        <tr>
                          <td>Pazarlama Bilgileri </td>
                          <td>Çerezler, anket bilgileri</td>
                        </tr>

                        <tr>
                          <th rowspan="6" className="text-center" style={{ verticalAlign: 'middle' }}>
                            Çevrimiçi Abone
                            <span className="text-primary">/Üye</span>
                          </th>
                          <td>Kimlik</td>
                          <td>Ad-Soyad, T.C. kimlik numarası</td>
                        </tr>
                        <tr>
                          <td>İletişim</td>
                          <td>E-posta, telefon/cep telefonu, adres, iş yeri adresi, fatura adresi</td>
                        </tr>
                        <tr>
                          <td>Müşteri İşlem Bilgileri </td>
                          <td>Ürün ve hizmet satın alma geçmişi bilgileri, fatura bilgileri, talep ve şikayet bilgileri</td>
                        </tr>
                        <tr>
                          <td>İşlem Güvenliği </td>
                          <td>IP Adresi, İnternet Sitesi Giriş, Çıkış ve Gezinme Bilgisi, Log Kayıtları,Cihaz MAC Adresi </td>
                        </tr>
                        <tr>
                          <td>Hukuki İşlem Bilgileri </td>
                          <td>
                            Adli, İdari, Düzenleyici ve Denetleme Kuruluşları ile Paylaşılan Bilgiler, İhbarname ve İhtarname Bilgileri, Sözleşme
                            bilgileri{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Pazarlama Bilgileri</td>
                          <td>Çerezler, anket bilgileri </td>
                        </tr>
                      </tbody>
                    </table>
                  </p>
                  <br />
                  <p className="">Yukarıdaki tabloda yer alan kişisel verileriniz, aşağıdaki amaçlarla sınırlı olmak üzere işlenmektedir.</p>
                </div>
                <div className="mb-3 px-5">
                  <ul>
                    <li className="mb-3">
                      <b> Kimlik Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi </li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi </li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi </li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>İletişim Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>İşlem Güvenliği Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Müşteri İşlem Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Finansal Bilgiler</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Hukuki İşlem Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Pazarlama Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Reklam/kampanya/promosyon süreçlerinin yürütülmesi </li>
                        <li>Pazarlama analiz çalışmalarının yürütülmesi</li>
                        <li>Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi</li>
                        <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi</li>
                        <li> İş süreçlerinin iyileştirilmesine yönelik önerilerin alınması ve değerlendirilmesi </li>
                      </ul>
                    </li>
                  </ul>
                  <br />
                </div>
                <div className="mb-3">
                  <p>
                    ‘‘İşlenen Kişisel Verileriniz ve Kişisel Verilerinizin İşlenme Amaçları’’ başlığı altındaki tabloda yer alan ve Wise&Rise’ı
                    çevrimiçi ortamdan ziyaretiniz kapsamındaki sıfatınıza göre değişiklik arz eden kişisel verileriniz arasında, ödeme
                    gerçekleştirdiğiniz banka veya kredi kartı bilgileriniz yer almamaktadır. Ödeme işlemini gerçekleştirdiğiniz banka veya kredi
                    kartı bilgileriniz, Şirketimiz tarafından veri işleme faaliyetine konu edilmemektedir. Banka veya kredi kartı bilgileriniz,
                    Wise&Rise’a güvenli ödeme sistemi hizmeti sağlayan kuruluşlar tarafından işlenir ve saklanır.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="h5 mb-2">3. Kişisel Verilerinizin Toplanma Yöntemleri ve Hukuki Sebebi </h3>
                  <p className="mb-0">
                    Kişisel verileriniz, işbu aydınlatma metninde açıklanan hukuki sebepler ve işleme amaçları kapsamında, hizmet ve işleme amacına
                    göre farklılık göstermekle birlikte; Wise&Rise’ın internet adresi üzerindeki her türlü işlem için bilgi girişi yaptığınız
                    elektronik formlar, Şirketimize fiziki olarak teslim ettiğiniz belgeler, Wise&Rise’ın internet adresi üzerinden yaptığınız hizmet
                    alım işlemleri, telefon, e-posta ve sair yöntemlerle oluşturduğunuz talep, şikayet, başvuru gibi bilgi ve belge aktarımı
                    faaliyetleri aracılığıyla, otomatik ya da otomatik olmayan yollarla sözlü, yazılı veya elektronik olarak, ‘‘İşlenen Kişisel
                    Verileriniz ve Kişisel Verilerinizin İşlenme Amaçları’’ başlığı altındaki tabloda yer alan amaçlar doğrultusunda, Şirketimiz
                    tarafından sunulan hizmetlerin belirlenen yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasadan doğan
                    mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi amacı ile toplanmaktadır.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="h5 mb-3">4. Kişisel Verilerinizin Aktarılması </h3>
                  <h3 className="h5 mb-1"> Yurtiçi</h3>
                  <p className="mb-0">
                    Veranova Medya, kişisel verilerin aktarılmasında Kanun’da öngörülen ve Kurul tarafından alınan karar ve ilgili düzenlemelere uygun
                    bir şekilde hareket etmektedir.
                  </p>
                  <p>
                    Veranova Medya tarafından işlenen kişisel veriler ilgili kişinin açık rızası olmaksızın üçüncü kişilere (gerçek veya tüzel)
                    aktarılamaz. Ancak Kanun veya diğer ilgili mevzuatta aktarılmasının zorunlu olduğu hallerde Veranova Medya tarafından işlenmiş
                    olan kişisel veriler mevzuatta öngörülen şekilde ve belirtilen sınırlamalara bağlı olarak yukarıda sayılan veri işleme amaçları
                    dâhilinde “Kanunlarda açıkça öngörülmesi”, “Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
                    sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması”, “Veri sorumlusunun hukuki yükümlülüğünü yerine
                    getirebilmesi için zorunlu olması.”, “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
                    menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebeplerine dayalı olarak, iş ortakları, tedarikçiler, faaliyetlerin
                    gerçekleştirilmesi kapsamında yetkili özel hukuk gerçek veya tüzel kişileri ile yetkili kılınan idari veya adli kurum veya
                    kuruluşa aktarılabilmektedir.
                  </p>
                  <p>
                    Veranova Medya, işlemiş olduğu kişisel verileri bu metinde belirtilen veri işleme amaçlarıyla sınırlı, bağlantılı ve her koşulda
                    Kanun’da öngörülen şartlara uygun olarak; yurt içinde yerleşik üçüncü kişilere aktarabilmektedir. Bu kapsamda kişisel veriler
                    aktarılabileceği üçüncü kişiler adli, idari, denetleyici, düzenleyici kamu kurum ve kuruluşları; iş ortaklarımız; hissedarlarımız;
                    topluluk şirketlerimiz; hukuki, mali ve vergi danışmanlarımız; tedarikçilerimiz ve denetçiler olarak ifade edilmelidir.
                  </p>
                  <h3 className="h5 mb-1"> Yurdışı</h3>
                  <p>
                    Kanun’un 9. maddesi uyarınca, kişisel verilerin yurt dışına aktarılabilmesi için ilgili kişinin açık rızasının bulunması
                    zorunludur. Aksi halde kişisel veriler, Kanun’un 5. ve 6. maddelerinde belirtilen şartlardan birinin varlığı ve kişisel verinin
                    aktarılacağı yabancı ülke, ülke içerisindeki sektörler veya uluslararası kuruluşlar hakkında yeterlilik kararı bulunması durumunda
                    yurt dışına aktarılabilmektedir.
                  </p>
                  <p>
                    Yeterlilik kararı bulunmaması halinde; Kanun’un 5. ve 6. maddelerinde belirtilen şartlardan birinin varlığı ile veri sahibinin
                    aktarımın yapılacağı ülkede de haklarını kullanma, etkili kanun yollarına başvurma imkânının bulunması kaydıyla ve “Yurt dışındaki
                    kamu kurum ve kuruluşları veya uluslararası kuruluşlar ile Türkiye'deki kamu kurum ve kuruluşları veya kamu kurumu niteliğindeki
                    meslek kuruluşları arasında yapılan uluslararası sözleşme niteliğinde olmayan anlaşmanın varlığı ve Kurul tarafından aktarıma izin
                    verilmesi”, “Ortak ekonomik faaliyette bulunan teşebbüs grubu bünyesindeki şirketlerin uymakla yükümlü olduğu, kişisel verilerin
                    korunmasına ilişkin hükümler ihtiva eden ve Kurul tarafından onaylanan bağlayıcı şirket kurallarının varlığı”, “Kurul tarafından
                    ilan edilen, veri kategorileri, veri aktarımının amaçları, alıcı ve alıcı grupları, veri alıcısı tarafından alınacak teknik ve
                    idari tedbirler, özel nitelikli kişisel veriler için alınan ek önlemler gibi hususları ihtiva eden standart sözleşmenin varlığı”,
                    “Yeterli korumayı sağlayacak hükümlerin yer aldığı yazılı bir taahhütnamenin varlığı ve Kurul tarafından aktarıma izin verilmesi”
                    şeklindeki uygun güvencelerden birinin taraflarca sağlanması halinde yurt dışına aktarılabilir.
                  </p>
                  <p>
                    Yeterlilik kararının bulunmaması ve yukarıda sayılan uygun güvencelerden herhangi birinin sağlanmaması durumunda da, arızi olmak
                    kaydıyla; “İlgili kişinin, muhtemel riskler hakkında bilgilendirilmesi kaydıyla, aktarıma açık rıza vermesi”, “Aktarımın, ilgili
                    kişiyle veri sorumlusu arasındaki bir sözleşmenin ifası veya ilgili kişinin talebi üzerine alınan sözleşme öncesi tedbirlerin
                    uygulanması için zorunlu olması”, “Aktarımın, ilgili kişi yararına veri sorumlusu ve diğer bir gerçek veya tüzel kişi arasında
                    yapılacak bir sözleşmenin kurulması veya ifası için zorunlu olması”, “Aktarımın üstün bir kamu yararı için zorunlu olması”, “Bir
                    hakkın tesisi, kullanılması veya korunması için kişisel verilerin aktarılmasının zorunlu olması”, “Fiili imkânsızlık nedeniyle
                    rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik bulunmayan kişinin kendisinin ya da bir başkasının hayatı
                    veya beden bütünlüğünün korunması için kişisel verilerin aktarılmasının zorunlu olması”, “Kamuya veya meşru menfaati bulunan
                    kişilere açık olan bir sicilden, ilgili mevzuatta sicile erişmek için gereken şartların sağlanması ve meşru menfaati bulunan
                    kişinin talep etmesi kaydıyla aktarım yapılması” hallerinden birinin varlığı halinde kişisel veriler yurt dışına aktarılabilir.
                  </p>
                  <p>
                    Kişisel veriler, uluslararası sözleşme hükümleri saklı kalmak kaydıyla, Türkiye’nin veya ilgili kişinin menfaatinin ciddi biçimde
                    zarar göreceği durumlarda, ancak ilgili kamu kurum veya kuruluşunun görüşü alınarak Kurul’un izniyle yurt dışına aktarılabilir.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="h5 mb-3">5. Kişisel Verilerinizin Muhafaza Edilme Süresi</h3>
                  <p className="mb-0">
                    Şirketimiz kişisel verilerinizi ancak ilgili mevzuatta belirtildiği veya işlendikleri amaç için gerekli olan süre kadar muhafaza
                    etmektedir. Bu kapsamda, öncelikle ilgili mevzuatta kişisel verilerin saklanması için bir süre belirlenmişse bu süreye uygun
                    davranmakta, bir süre belirlenmemişse kişisel verileri işlendikleri amaç için gerekli olan süre kadar saklamaktadır. Sürenin
                    bitimi veya işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verileriniz Kanun’un 7. maddesi uyarınca
                    silinmekte, yok edilmekte veya anonim hale getirilerek Şirketimizce kullanılmaya devam edilebilmektedir.
                  </p>
                </div>

                <div className="mb-4">
                  <h3 className="h5 mb-3">6. Yasal Haklarınız</h3>
                  <p className="mb-0">
                    Kişisel veri sahibi olarak, Kanun’un 11. maddesi uyarınca, veri sorumlusu olan Veranova Medya’ya başvurarak;
                    <ul className="pl-5 mt-3">
                      <li>Kişisel verinizin işlenip işlenmediğini öğrenme, </li>
                      <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                      <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                      <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                      <li>
                        Kişisel verileriniz eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                        kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                      </li>
                      <li>
                        Kanun’un 7. maddesinde öngörülen şartlar (Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
                        kişisel verinizin işlenmesini gerektiren sebeplerin ortadan kalkması hâli) çerçevesinde kişisel verilerin silinmesini veya yok
                        edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                      </li>
                      <li>
                        İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
                        çıkmasına itiraz etme,{' '}
                      </li>
                      <li>
                        Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme
                        haklarınız bulunmaktadır. Kanun’un 28. Maddesinde öngörülen istisnai haller saklıdır.
                      </li>
                    </ul>
                    <br /> <br />
                    Yukarıda sayılan haklarınıza ilişkin taleplerinizi, ‘‘Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre, ıslak
                    imzanızın bulunması koşuluyla, buradan ulaşabileceğiniz{' '}
                    <Link to="/basvuru-formu" target="_blank" className="text-primary">
                      Başvuru Formu
                    </Link>{' '}
                    ile ‘‘Akat Mah. Cebeci Cad. No: 65 Beşiktaş/İSTANBUL’’ adresine; veya <a href="mailto:info@wisenrise.com">info@wisenrise.com </a>{' '}
                    veya (...)@hs01.kep.tr adresine yazılı olarak ve kimlik teyidinizin yapılmasını sağlayarak iletebilirsiniz. Şirketimiz başvuru
                    taleplerini, Kanun’un 13. maddesine uygun olarak, talebin niteliğine göre ve en geç 30 (otuz) gün içinde sonuçlandıracaktır.
                    <br /> <br />
                    İşlemin maliyet gerektirmesi halinde, Kişisel Verilerin Korunması Kurumu tarafından belirlenen tarife uygulanacaktır. Talebin
                    reddedilmesi halinde, ret nedeni/nedenleri yazılı olarak veya elektronik ortamda gerekçelendirilecektir.
                    <br /> <br />
                    Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı talep
                    ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz
                    konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor iseniz bu konuda özel olarak yetkili olmanız ve yetkinizi
                    belgelendirmeniz, başvurunun kimlik ve adres bilgilerini içermesi ve başvuruya kimliğinizi teyit edici belgelerin eklenmesi
                    gerekmektedir.
                  </p>
                </div>
                <div className="mt-5">
                  <span className="h6 d-block text-center">Saygılarımızla,</span>
                  <h5 className="h4 text-center">Veranova Medya ve Dijital Yayın Hizmetleri Anonim Şirketi </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
