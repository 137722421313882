import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getPlans } from '../../Services/AuthApi';

export default function TermsOfUse() {
  const [price, setPrice] = useState([]);
  useEffect(() => {
    getPlans('Yearly')
      .then((res) => {
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);
  return (
    <div>
      <div className=" " style={{ paddingTop: '80px', paddingBottom: '60px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Wise&Rise Kullanım Koşulları</h1>
                  <span>
                    <Link to="/">Anasayfa</Link> /<span className="text-primary">Wise&Rise Kullanım Koşulları</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main pt-3">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                <div className="mb-3">
                  <h3 className="h5 mb-2">1. Giriş </h3>
                  <p className="mb-0">
                    1.1. Okumakta olduğunuz Wise&Rise Kullanım Koşulları (“Bildirim”); Veranova Medya ve Dijital Yayın Hizmetleri Anonim Şirketi’nin
                    (“Veranova Medya”) sahibi olduğu web sitesi, mobil uygulama ve Wise&Rise isimli dijital platform (birlikte “Wise&Rise”)
                    aracılığıyla sunacağı hizmetlerden faydalanmak isteyen kullanıcılara yönelik kuralları belirlemektedir.
                    <br />
                    <br />
                    1.2. Wise&Rise’ı ziyaret etmeniz ve/veya Wise&Rise’a üye olmanızla birlikte bu bildirimin doğrudan muhatabı olduğunuzu belirtmek
                    isteriz. Wise&Rise’a erişebilmeniz ve kullanımınız, bu bildirimde bulunan hükümlere uymanıza bağlıdır. Bu sebeple bu bildirimi
                    dikkatlice okumanızı tavsiye ederiz.
                    <br />
                    <br />
                    1.3. Wise&Rise; üyelerine internete bağlı telefon, bilgisayar, televizyon gibi cihazlar aracılığıyla internet teknolojisi
                    üzerinden, çeşitli konularda uzmanlığı / yetkinliği bulunan eğitmenlerin icra ettiği / yer aldığı içeriklere erişilebilmesini
                    sağlayan bir hizmet sunar. Bu üyelik hizmeti, tercih ettiğiniz plana göre sınırlı sayıdaki bazı kişilerin kullanımına sunma imkânı
                    tanıyabilmekle birlikte, kural olarak kişiye özel niteliktedir.
                    <br />
                    <br />
                    1.4. Wise&Rise, Veranova Medya tarafından yönetilmektedir
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h3 className="h5 mb-2">2. Üyelik </h3>
                  <p className="mb-0">
                    2.1. Okudum, onaylıyorum yaptığınızda / butonuna tıkladığınızda, sizlere Wise&Rise bünyesinde sunulan metin, form, bildirim,
                    sözleşme gibi tüm hukuki belgelerin maddelerinin her satırını okuduğunuzu ve tam olarak anladığınızı kabul etmiş sayılırsınız. Bu
                    nedenle yasal uyarıları okumak önemlidir.
                    <br />
                    <br />
                    2.2. Wise&Rise üyeliği için üye adayı ile Veranova Medya arasında Üyelik ve Mesafeli Satış Sözleşmesi (‘‘Mesafeli Sözleşme’’)
                    akdedilmesi gerekir. Sunulan tüm hukuki belgeleri okudum, onaylıyorum yaptığınıza / butonuna tıkladığınıza ilişkin beyanınızın
                    (dijital ortamdaki eyleminizin) Wise&Rise’ın bilişim sistemlerine ulaşmasıyla birlikte üyelik işlemi gerçekleşmiş ve tamamlanmış
                    olur.
                    <br />
                    <br />
                    2.3. Bu bildirimin -3- numaralı maddesinde sunulan üyelik tür ve planlarını Wise&Rise’da yer alan Planlar sekmesinden her zaman
                    için detaylı bir şekilde inceleyebilirsiniz. Veranova Medya’nın zaman zaman özel teklifler, planlar veya üyelikler sunma hakkı her
                    zaman saklıdır.
                    <br />
                    <br />
                    2.4. On sekiz (18) yaşından küçük kişilerin Wise&Rise’a üye olması Veranova Medya tarafından kabul edilmemektedir. Bu hususun
                    ihlalinin tespit edilmesi hâlinde üyeliğiniz sonlandırılır. Bir üyenin, 18 yaşından küçük kişileri Wise&Rise içeriklerinden
                    yararlandırması ancak velayet/vesayet sahibi kişilerin onayı ve her hâlde bir yetişkin gözetiminde mümkündür. Aksi haller ve bu
                    süreçler için tüm sorumluluk ilgili üyeye ait olup Veranova Medya, sorumluluk kabul etmemektedir.
                    <br />
                    <br />
                    2.5. Wise&Rise hizmetini kullanabilmeniz için, ‘‘www.wisenrise.com’’ adresinde yer alan görsel-işitsel kayıtları/videoları
                    izleyebilmenize imkân sağlayan bir internet erişimi ile cihaza sahip olmanız ve tercih edilen abonelik türüne uygun ödeme aracı
                    sunmanız gerekir.
                    <br />
                    <br />
                    2.6. Veranova Medya tarafından belirlenecek bazı Wise&Rise içeriklerinin, üyeler tarafından cihaza indirmek (download) suretiyle
                    çevrimdışı (offline) olarak erişilebilmesi mümkündür. Veranova Medya, üyelerinin çevrimdışı olarak erişebilmesine imkân sağladığı
                    içeriklerine -sayılanlarla sınırlı olmamak üzere- izleme sayısı, periyodu ve erişim süresi gibi kısıtlamalar uygulayabilir.
                    <br />
                    <br />
                    2.7. Wise&Rise üyeliği; Mesafeli Sözleşme şartlarına uyulduğu ve üyenin hizmet alma iradesi devam ettiği müddetçe; Veranova Medya
                    tarafından belirlenen süre boyunca devam eder.
                    <br />
                    <br />
                  </p>
                  <br />
                  <p className="">Yukarıdaki tabloda yer alan kişisel verileriniz, aşağıdaki amaçlarla sınırlı olmak üzere işlenmektedir.</p>
                </div>
                <div className="mb-3">
                  <h3 className="h5 mb-2">3. Üyelik Planları ve Kapsamı </h3>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th className="w-50 text-center">PEŞİN </th>
                        <th className="text-center">TAKSİT</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        <td>1 Hesap - 4 Profil</td>
                        <td>1 Hesap - 4 Profil</td>
                      </tr>
                      <tr>
                        <td>{price?.[3]?.name}</td>
                        <td>{price?.[2]?.name}</td>
                      </tr>
                      <tr>
                        <td>
                          <ul className="pl-4 mt-2">
                            <li> 9 Kategori+20’den fazla içerik</li>
                            <li>Her Ay Yeni İçerikler</li>
                            <li>Her Ay Size Özel Bonus İçerikler</li>
                            <li>Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</li>
                            <li> İzlerken Not Alma Özelliği</li>
                            <li>Mobil Cihazdan Podcast Dinleme Özelliği</li>
                            <li>Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</li>
                            {/* <li> İstediğin Zaman İptal Etme Seçeneği</li> */}
                          </ul>
                        </td>
                        <td>
                          <ul className="pl-4 mt-2">
                            <li> 9 Kategori+20’den fazla içerik</li>
                            <li>Her Ay Yeni İçerikler</li>
                            <li>Her Ay Size Özel Bonus İçerikler</li>
                            <li>Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</li>
                            <li> İzlerken Not Alma Özelliği</li>
                            <li>Mobil Cihazdan Podcast Dinleme Özelliği</li>
                            <li>Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</li>
                            {/* <li> İstediğin Zaman İptal Etme Seçeneği</li> */}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p>
                    Belirli Süreli, Süresi Sona Erdiğinde Kendiliğinden Yenilenen, Ön Ödemeli ve Çoklu Erişime Elverişli Abonelik Hizmeti Yıllık Plan
                    <ul className="pl-4 mt-2">
                      <li>
                        Çeşitli aralıklarla yenilenen; İş Dünyası, Sanat, Kişisel Gelişim, Gastronomi gibi kategorilerdeki içeriklere internet
                        ortamından erişim imkânı sağlar.
                      </li>
                      <li>İçeriklere aynı anda 4 ayrı cihaz üzerinden erişim imkânı sağlar.</li>
                    </ul>
                  </p>
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">4. Ödemeler </h3>
                  <p className="mb-0">
                    4.1. Ödemeler banka, kredi kartı, ön ödemeli kart ile Wise&Rise’da yer alan ödeme bölümünden Iyzico altyapısı ile güvenli şekilde
                    gerçekleştirilebilir. Kullanılacak kart tipi durum gerektirdiğinde sınırlanabilir.
                    <br />
                    <br />
                    4.2. Türkiye dışından oluşturulacak üyeliklerde Mastercard ya da VISA altyapısı kullanan herhangi bir kredi kartıyla ödeme
                    gerçekleştirilmesi mümkündür.
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">5. Üyelik Hakkının Devredilemezliği </h3>
                  <p className="mb-0">
                    5.1. Wise&Rise içerikleri, yalnızca kişisel ve ticari nitelikte olmayan kullanıma yönelik olup üyelik hakkı, üçüncü kişilere
                    devredilemez.
                    <br />
                    <br />
                    5.2. Hesabınızı oluştururken vermiş olduğunuz bilgilerin doğruluğundan ve belirlediğiniz şifrenin korunmasından yalnızca siz
                    sorumlu olursunuz. Giriş bilgilerinizin üçüncü kişilere kullandırmak amacıyla paylaşıldığının tespiti halinde üyeliğiniz Veranova
                    Medya tarafından sonlandırılabilir.
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">6. Değişiklik ile İçerik Ekleme ve Kaldırma Hakkı </h3>
                  <p className="mb-0">
                    6.1. Veranova Medya, en iyi kullanıcı deneyimini sunabilmek amacıyla gerektiğinde teknolojide, hizmetlerde ya da ücret
                    politikasında her türlü değişikliği gerçekleştirebilir. Bu süreçte üyelere karşı Veranova Medya’nın yasal sınırlar dâhilinde
                    herhangi bir yükümlülüğü bulunmaz. Bu değişiklikler; sayılanlarla sınırlı olmamak üzere teknik sorunlar, bakım dönemleri, testler,
                    resmi otorite kararları, üçüncü taraf saldırıları sebebiyle çeşitli kesintiler; aylık abonelik bedelindeki değişiklikler;
                    içeriklerin güncellenmesi, içerik kaldırma ya da eklemeyi kapsar. Bu durumlarla ilgili üyeler mutlaka bilgilendirilir.
                    <br />
                    <br />
                    6.2. Hiçbir üyenin Wise&Rise’da bulunan içeriklerin kaldırılmasından ya da Wise&Rise’a içerik eklenmesinden dolayı ücret iadesi
                    talebi bulunmamaktadır.
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">7. Fikri ve Sınai Mülkiyet Hakları, Ticaret Hakları</h3>
                  <p className="mb-0">
                    7.1. Wise&Rise’a üye olmak, Wise&Rise üzerindeki videolar, yazılı metin dokümanları üzerinde hiçbir surette lisans ya da ruhsat
                    hakkı tanımaz. Üyelerin erişim sağlayacağı tüm görsel-işitsel ve/veya yazılı içeriklerin, Wise&Rise yazılım kodlarının tüm telif
                    hakları Veranova Medya’ya veya içeriğin üretilmesine katkı sağlayan iş ortaklarına aittir. Üyelerin erişimine açılacak olan hiçbir
                    içeriği işleme, çoğaltma, yayma, temsil, umuma iletim, kiralama ve/veya satma hakkı yoktur. <br />
                    <br />
                    7.2. Wise&Rise sınai mülkiyet hakları, ticaret unvanı ve alan adları yalnızca Veranova Medya’ya aittir. Bunlar taklit edilemez,
                    kopyalanamaz ve ticari/bireysel amaçla kullanılamaz.
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">8. Kişisel Verilerin Korunması ve Çerezler</h3>
                  <p className="mb-0">
                    8.1. Üyenin Wise&Rise içeriklerine göz atabilmesi ve üyeye daha iyi bir hizmet sunulabilmesi adına, Wise&Rise’a giriş yaptığınız
                    bilgiler doğrultusunda isim, soyisim, doğum tarihi, telefon numarası, e-posta adresi gibi kişisel veriler kaydedilmektedir. <br />
                    <br />
                    8.2. Wise&Rise’da yer alan çerez (tanımlama bilgisi) uygulamalarını kabul etmeniz halinde, çerezler (tanımlama bilgileri)
                    <Link className="text-danger" to="/cerez-politikasi">
                      {' '}
                      Çerez Politikası
                    </Link>{' '}
                    uyarınca ziyaretçi ve/veya üyenin cihazına yerleştirilir.
                    <br />
                    <br />
                    8.3. Detaylı bilgi için{' '}
                    <Link className="text-danger" to="/gizlilik-politikasi">
                      Aydınlatma Metni
                    </Link>{' '}
                    ’ni incelemelisiniz.
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">9. Sorumluluk Sınırlaması ve Garanti Reddi</h3>
                  <p className="mb-0">
                    9.1. Wise&Rise üzerinden sağlanacak içeriklere, içeriklerin çeşitliliğine, sayısına ve içeriklerde yer alan tüm bilgi, öneri ve
                    tavsiyelerin bilimsel ve/veya yorumsal doğruluğuna ilişkin olarak Veranova Medya tarafından herhangi bir surette taahhütte
                    bulunulmamaktadır.
                    <br />
                    <br />
                    9.2. Veranova Medya’nın, Wise&Rise’da yer alan ve üçüncü taraflara ait bağlantılı linklerde yer alan reklamların konusunu teşkil
                    edebilecek ürün ve/veya hizmetlere ilişkin hiçbir sorumluluğu bulunmamaktadır.
                    <br />
                    <br />
                    9.3. Bazı uygulama ve cihazlar hizmetlerimize erişim sağlamada Wise&Rise ile uyum içinde olmayabilir. Bu yüzden üçüncü taraf
                    uygulama ve cihazları ile erişim sağlanacak hizmetlere yönelik Veranova Medya’nın herhangi bir garanti sunmamaktadır.
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">10. Üyeliğin Sonlandırılması</h3>
                  <p className="mb-0">
                    10.1. Wise&Rise üyeliğinizi herhangi bir gerekçe belirtmeksizin dilediğiniz zaman mevcut faturalandırma döneminin bitiminden
                    itibaren geçerli olmak kaydıyla tek taraflı şekilde sonlandırabilirsiniz.
                    <br />
                    <br />
                    10.2. Bu işlem için web tarayıcısı üzerinden giriş yapıldıktan sonra “Planlarım” kısmından “İptal et” butonuna tıklanmalıdır.
                    <br />
                    <br />
                    10.3. Üyeliğin sonlandırılması durumunda Wise&Rise erişiminiz uygun şekilde kapatılır.{' '}
                    <Link className="text-danger" to="/mesafeli-sozlesme">
                      {' '}
                      Mesafeli Sözleşme
                    </Link>{' '}
                    hükümleri saklıdır.
                    <br />
                    <br />
                  </p>
                  <br />
                </div>

                <div className="mb-3">
                  <h3 className="h5 mb-2">11. Muhtelif Hükümler</h3>
                  <p className="mb-0">
                    11.1. Bu bildirim ya da üye ile akdedilen{' '}
                    <Link className="text-danger" to="/mesafeli-sozlesme">
                      {' '}
                      Mesafeli Sözleşme
                    </Link>
                    ’nin herhangi bir veya birden fazla hükmünün geçersiz, yasalara aykırı veya uygulanamaz ilan edilmesi durumunda, kalan hükümlerin
                    geçerliliği bu durumdan etkilenmez.
                    <br />
                    <br />
                    11.2. Üyelik ile ilgili her türlü bilgi, yalnızca elektronik ortamda ve elektronik formatta aktarılır. Bu sebeple sağlanan
                    iletişim bilgilerinin doğru ve güncel olduğundan emin olmalısınız. Mevcut halde bir değişiklik olması durumunda; üye, derhal
                    ilgili değişikliği Wise&Rise üye profil sayfasından veya{' '}
                    <a className="text-danger" href="mailto:info@wisenrise.com">
                      info@wisenrise.com
                    </a>{' '}
                    adresinden bildirmelidir.
                    <br />
                    <br />
                    11.3. İnternette yaşanan kesintiler, hız düşmesi ve benzeri sebeplerle hizmette yaşanabilecek kesinti, görüntü kalitesi ve
                    güvenlik problemlerinden ve/veya ülkede genel bir karışıklığın ortaya çıkması savaş, terör, grev, genel elektrik kesintisi,
                    deprem, su baskını, pandemi, olağanüstü hal, siber saldırı, cihazlara virüs bulaşmış olması veya benzeri bir mücbir sebeplerden
                    Veranova Medya sorumlu değildir.
                    <br />
                    <br />
                    11.4. Veranova Medya, üyelik ilişkisine dair haklarını ve yükümlülüklerini herhangi bir zamanda bir başkasına devir ve temlik
                    edebilir.
                    <br />
                    <br />
                    11.5. Ortaya çıkabilecek uyuşmazlıklarda Türk Hukuku uygulanır. Ticaret Bakanlığınca yasa gereği her yıl belirlenen/ilan edilen
                    parasal sınırlar dâhilinde İl ve İlçe Tüketici Hakem Heyetleri, bu sınırları aşan durumlarda Tüketici Mahkemeleri yetkili ve
                    görevlidir. Bu çerçevede, dilerseniz kendi yerleşim yeriniz dilerseniz Veranova Medya’nın yerleşim yerindeki Hakem Heyetleri ve
                    Tüketici Mahkemelerine başvurabilirsiniz.
                    <br />
                    <br />
                    11.6. Bu bildirimde bulunan hüküm ve koşullar gerektiğinde güncellenebilir. Somut değişiklikler olması durumunda üyelerimizi
                    geçerlilik tarihinden makul süre önce bilgilendiririz. Güncel versiyona her zaman Wise&Rise üzerinden erişilebilir.
                    <br />
                    <br />
                    11.7. Bu bildirim, yayınlandığı an yürürlüğe girer ve herhangi bir şekilde Wise&Rise’ı ziyaret eden, Wise&Rise’a giriş yapan kişi
                    açısından ayrıca bir işleme gerek kalmaksızın bağlayıcı olur. 10.3. Üyeliğin sonlandırılması durumunda Wise&Rise erişiminiz uygun
                    şekilde kapatılır. <br />
                    <br />
                  </p>
                  <br />
                </div>
                <div className="mt-5">
                  <span className="h6 d-block text-center">Saygılarımızla,</span>
                  <h5 className="h4 text-center">VERANOVA MEDYA VE DİJİTAL YAYIN HİZMETLERİ A.Ş. </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
